import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'components/Avatar/Avatar'
import Flex from 'components/Flex'
import Style from './Dropdown.module.scss'

const Dropdown = ({ children, url, size }) => {
  return (
    <div className={Style.dropdown}>
      <Avatar url={url} size={size} />
      <Flex
        className={Style.dropdownList}
        container
        flexDirection="column"
        alignItems="flex-end"
        padding="0 2rem 0 3rem"
      >
        {children}
      </Flex>
    </div>
  )
}

Dropdown.defaultProps = {
  url: '',
  size: null,
  children: '',
}

Dropdown.propTypes = {
  url: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.node,
}

export default Dropdown
