import React from 'react'
import { useTranslation } from 'react-i18next'
import Filters from 'components/Filters/Filters'
import Flex from 'components/Flex'

import { PRIMARY_COLOR } from 'constants/colors'
import VehiclesSlider from './VehiclesSlider/VehiclesSlider'

const Vehicles = ({ history }) => {
  const { t } = useTranslation('vehicles')
  return (
    <>
      <Filters noCalendar goBack={history.goBack} />
      <div className="content">
        <Flex container flexDirection="column" height="auto">
          <h1>{t('vehicles:title')}</h1>
          <VehiclesSlider />
          <b
            style={{
              textAlign: 'center',
              fontSize: '18px',
              color: PRIMARY_COLOR,
              margin: '1.6rem 0',
            }}
          >
            {t('vehicles:description')}
          </b>
        </Flex>
        <br />
      </div>
    </>
  )
}

export default Vehicles
