import React from 'react'

function useSnackbar() {
  const [isActive, setIsActive] = React.useState(false)
  const [message, setMessage] = React.useState()
  const [severity, setSeverity] = React.useState()

  React.useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false)
      }, 3000)
    }
  }, [isActive])

  const openSnackBar = (msg, svt) => {
    setMessage(msg)
    setIsActive(true)
    setSeverity(svt)
  }

  return { isActive, message, severity, openSnackBar }
}

export default useSnackbar
