import React from 'react'
import { useTranslation } from 'react-i18next'

const RallyDimension = (value) => {
  const { t } = useTranslation('common')
  return (
    <>
      <b>{t('common:tire.av')}:</b>
      {`${value.row.original.rally_dimension_front_dimension} / `}
      <b>{t('common:tire.ar')}:</b>
      {value.row.original.rally_dimension_rear_dimension}
    </>
  )
}

export default RallyDimension
