import React, { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import Style from './PDFViewer.module.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  function next() {
    setPageNumber((currPage) => Math.min(currPage + 1, numPages))
  }

  function prev() {
    setPageNumber((currPage) => Math.max(currPage - 1, 1))
  }

  return (
    <Document
      file={pdf}
      onLoadSuccess={onDocumentLoadSuccess}
      className={Style.document}
    >
      <Page key={numPages} pageNumber={pageNumber} className={Style.page} />
      <div className={Style.pagination}>
        <button type="button" onClick={prev}>
          ‹
        </button>
        <span>
          {pageNumber} of {numPages}
        </span>
        <button type="button" onClick={next}>
          ›
        </button>
      </div>
    </Document>
  )
}

export default PDFViewer
