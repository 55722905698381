import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'

import { convertBarTopsi } from '../utils/convertPressure'
import { PreferencesContext } from '../contexts/PreferencesContext'
import { PREFERENCE_PRESSURE_DEFAULT } from '../constants/preferences'

const PressureDisplay = ({ pressureValue }) => {
  const {
    preferences: { pressure },
  } = useContext(PreferencesContext)

  return (
    <b>
      {pressure === PREFERENCE_PRESSURE_DEFAULT
        ? pressureValue
          ? pressureValue.toFixed(2)
          : ''
        : convertBarTopsi(pressureValue).toFixed(2)}
      <span>{pressure === PREFERENCE_PRESSURE_DEFAULT ? 'b' : 'p'}</span>
    </b>
  )
}

PressureDisplay.defaultProps = {
  pressureValue: '',
}

PressureDisplay.propTypes = {
  pressureValue: PropTypes.number,
}

export default PressureDisplay
