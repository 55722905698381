import React from 'react'
import styled from 'styled-components'
import { ReactComponent as VehicleCarcasse } from 'img/Voiture_PressionTpsReel_Voiture_Carcasse.svg'
import { PRESSURE } from 'constants/tire'

const RunsRallyRealTimePressureVehicle = ({
  tireRL,
  tireFL,
  tireRR,
  tireFR,
  context,
}) => {
  const getColorByContext = (value) => {
    return context === PRESSURE ? value.press.color : value.temp.color
  }

  const Styles = styled.div`
    position: relative;

    .vehicleSVG {
      position: absolute;
      bottom: 0;
      right: 10px;

      & path:nth-child(2) {
        fill: ${getColorByContext(tireFL)};
      }

      & path:nth-child(3) {
        fill: ${getColorByContext(tireFR)};
      }

      & path:nth-child(4) {
        fill: ${getColorByContext(tireRL)};
      }

      & path:nth-child(5) {
        fill: ${getColorByContext(tireRR)};
      }
    }
  `

  return (
    <Styles>
      <VehicleCarcasse width={140} height={80} className="vehicleSVG" />
    </Styles>
  )
}

export default RunsRallyRealTimePressureVehicle
