import React, { useEffect } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import styled from 'styled-components'
import { ReactComponent as NextArrow } from 'img/icon_arrow_next.svg'
import paginate from 'utils/paginate'
import { useTranslation } from 'react-i18next'
import { useStateIfMounted } from 'use-state-if-mounted'
import Pagination from '../Pagination/Pagination'

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  height: 100%;
  }

  .noData {
    height: 100%;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & div {
      color: #27509B;
      padding: 1rem 0;
    }

    button {
      margin: 1rem;
      background-color: #fce500;
      padding: 0.5rem 2rem;
      border-radius: 5px;
      border-width: 0;
      cursor: pointer;
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
    }

    &__text {
      font-size: 14px;
    }
  }

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    border-radius: 10px;
    background-color: white;
    box-shadow: var(--box-shadow);
    height: 100%;

    thead tr {
      height: 97px;

      th svg.icon-asc, svg.icon-desc {
        margin-left: 11px;
      }

      th svg.icon-desc {
        transform: rotate(90deg);
      }

      th svg.icon-asc {
        transform: rotate(270deg);
      }

      th:last-child {
        display:none;
      }  

      th:nth-child(4n) {
        width: 2%;
      }
    }

    tbody tr {
      :hover {
        box-shadow: 0px 0px 32px rgba(39, 80, 155, 0.33);
        transform: scale(1);
      }
      :nth-child(2n + 1) {
        background-color: #F4F8FB;
      }
      cursor: pointer;
    }
    tr {
      height: 77px;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem 0rem 1rem 2rem;
      text-align: left;
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`
function Table({
  columns,
  data,
  fetchData,
  onEventClick,
  goRefresh,
  startingPeriodRange,
  finishPeriodRange,
}) {
  const [pagesToPaginate, setPagesToPaginate] = useStateIfMounted([])
  const [totalPagination, setTotalPagination] = useStateIfMounted(null)
  const totalCounts = data.rally_session_count
  const { t } = useTranslation(['common', 'button'])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: data.rally_sessions,
      initialState: {
        pageIndex: 0,
      },
      autoResetPage: false,
      autoResetSortBy: false,
      manualPagination: true,
      manualSorting: true,
      manualSortBy: true,
      pageCount: totalPagination,
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const { pages, totalPages } = paginate(totalCounts, pageIndex)
    setTotalPagination(totalPages)
    setPagesToPaginate(pages)

    fetchData(sortBy, pageIndex, startingPeriodRange, finishPeriodRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pageIndex, totalCounts, startingPeriodRange, finishPeriodRange])

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <NextArrow className="icon-desc" width="7" height="12" />
      ) : (
        <NextArrow className="icon-asc" width="7" height="12" />
      )
    ) : (
      ''
    )
  }

  return (
    <Styles>
      {data.length !== 0 ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{generateSortingIndicator(column)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} onClick={() => onEventClick(row)}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="noData">
          <div className="noData__title">{t('common:no_data')}.</div>
          <div className="noData__text">
            {t('common:no_data_description')} <br />
            {t('common:no_data_description_end')}
          </div>
          <button type="button" onClick={goRefresh}>
            {t('button:refresh')}
          </button>
        </div>
      )}

      {/* TODO: en attente des icones */}
      {data.length !== 0 && (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pagesToPaginate={pagesToPaginate}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageCount={totalPagination}
        />
      )}
    </Styles>
  )
}

export default Table
