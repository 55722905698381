import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Flex from 'components/Flex'
import OptimalRangeOperation from 'components/OptimalRangeOperation/OptimalRangeOperation'

import PREFERENCES from 'constants/preferences'
import { PreferencesContext } from 'contexts/PreferencesContext'
import DistanceDisplay from 'components/DistanceDisplay'
import { useTranslation } from 'react-i18next'
import Style from './KeyFigures.module.scss'

const KeyFigures = ({
  total_kilometers,
  session_count,
  front_optimal_range,
  rear_optimal_range,
}) => {
  const {
    preferences: { distance },
  } = useContext(PreferencesContext)
  const { t } = useTranslation(['homepage', 'common'])

  return (
    <Flex container flexDirection="row" className={Style.keyFigures}>
      <div className={`${Style.card} ${Style.key_kilometers}`}>
        <h2>
          {distance === PREFERENCES.DISTANCE.KM
            ? t('homepage:recorded.title_km')
            : t('homepage:recorded.title_miles')}
        </h2>
        <div className={Style.key}>
          {total_kilometers ? (
            <DistanceDisplay distanceValue={total_kilometers} noUnit />
          ) : (
            <span className={Style.noData}>{t('common:no_data')}</span>
          )}
        </div>
      </div>
      <div className={`${Style.card} ${Style.recorded_sessions}`}>
        <h2>{t('homepage:recorded.sessions_title')}</h2>
        <div className={Style.key}>
          {session_count || (
            <span className={Style.noData}>{t('common:no_data')}</span>
          )}
        </div>
      </div>
      <div className={`${Style.card} ${Style.optimal_ro}`}>
        <OptimalRangeOperation
          front_optimal_range={front_optimal_range}
          rear_optimal_range={rear_optimal_range}
          showImg
        />
      </div>
    </Flex>
  )
}

KeyFigures.defaultProps = {
  total_kilometers: null,
  session_count: null,
  front_optimal_range: null,
  rear_optimal_range: null,
}

KeyFigures.propTypes = {
  total_kilometers: PropTypes.number,
  session_count: PropTypes.number,
  front_optimal_range: PropTypes.number,
  rear_optimal_range: PropTypes.number,
}

export default KeyFigures
