import React, { useState } from 'react'
import Switch from 'react-switch'

import Flex from 'components/Flex'
import CamberAngleItem from 'components/CamberAngleItem/CamberAngleItem'
import { useTranslation } from 'react-i18next'
import Style from './RunsRallyMyVehicle.module.scss'

const RunsRallyMyVehicleCamberAngle = ({ front_camber, rear_camber }) => {
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation('run')

  return (
    <div className={Style.camberAngle}>
      <h3>{t('run:vehicle.camber_angle.title')} :</h3>
      <Flex
        container
        justifyContent="center"
        alignItems="center"
        padding="14px 0 0 0"
      >
        <span>{t('run:vehicle.camber_angle.front')}</span>
        <label style={{ margin: '0 8px' }}>
          <Switch
            onChange={(e) => setChecked(e)}
            checked={checked}
            onColor="#27509b"
            offColor="#A9D5EB"
            onHandleColor="#A9D5EB"
            offHandleColor="#27509b"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={18}
            height={18}
            width={37}
          />
        </label>
        <span>{t('run:vehicle.camber_angle.rear')}</span>
      </Flex>
      <Flex container justifyContent="center" padding="24px 0 0 0">
        <CamberAngleItem value={!checked ? front_camber : rear_camber} />
      </Flex>
    </div>
  )
}

export default RunsRallyMyVehicleCamberAngle
