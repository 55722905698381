import React, { useState } from 'react'
import Filters from 'components/Filters/Filters'
import MySessions from 'views/runs/MySessions/MySessions'

const Runs = ({ history }) => {
  const [startingPeriodRange, setStartingPeriodRange] = useState(undefined)
  const [finishPeriodRange, setFinishPeriodRange] = useState(undefined)

  const resetPeriodRange = () => {
    setStartingPeriodRange(undefined)
    setFinishPeriodRange(undefined)
  }

  return (
    <>
      <Filters
        goBack={history.goBack}
        startingPeriodRange={startingPeriodRange}
        finishPeriodRange={finishPeriodRange}
        changeStartingPeriodRange={(value) => setStartingPeriodRange(value)}
        changeFinishPeriodRange={(value) => setFinishPeriodRange(value)}
        resetPeriodRange={resetPeriodRange}
        context="runs"
      />
      <MySessions
        startingPeriodRange={startingPeriodRange}
        finishPeriodRange={finishPeriodRange}
      />
    </>
  )
}

export default Runs
