import React, { useContext, useEffect } from 'react'
import { NotificationContext } from 'contexts/NotificationContext'
import { getCatalogsItems } from 'services/catalogService'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useTranslation } from 'react-i18next'
import NewsList from './NewsList/NewsList'
import Style from './News.module.scss'

const News = () => {
  const [news, setNews] = useStateIfMounted([])
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation(['homepage', 'common'])

  useEffect(() => {
    async function fetchNews() {
      try {
        const token = localStorage.getItem('access_token')
        const {
          data: { catalog_items },
        } = await getCatalogsItems(token, null, 3)
        setNews(catalog_items)
      } catch (error) {
        openSnackBar(
          `${t('homepage:news.notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const emptyNews = <span className={Style.noData}>{t('common:no_data')}</span>

  return (
    <div className={Style.card}>
      <h2>{t('homepage:news.title')}</h2>
      {news.length === 0 ? emptyNews : <NewsList news={news} />}
    </div>
  )
}

export default News
