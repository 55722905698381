import React from 'react'
import PropTypes from 'prop-types'
import Flex from 'components/Flex'
import { ReactComponent as Vehicle } from 'img/vehicle01.svg'
import PressureDisplay from 'components/PressureDisplay'
import { useTranslation } from 'react-i18next'
import Style from './PressureRecommendation.module.scss'

// TODO
const PressureRecommendation = ({ pressureRecommendation }) => {
  const { t } = useTranslation(['vehicle', 'common'])
  return (
    <Flex className={Style.card} height="100%" padding="0 25px" flex="1 1 0">
      <h3>{t('vehicle:pressure_recommendation.title')}</h3>
      <div className={Style.contentTire}>
        <Vehicle width="81px" height="167px" />
        <div className={`${Style.tire} ${Style.tireFl}`}>
          <div className={Style.tireTitle}>{t('common:pressure.tire_fl')}</div>
          <div className={Style.tireCold}>{t('common:pressure.cold')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.front_min_cold_pressure}
          />
          <div className={Style.tireHot}>{t('common:pressure.hot')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.front_pressure}
          />
        </div>
        <div className={`${Style.tire} ${Style.tireFr}`}>
          <div className={Style.tireTitle}>{t('common:pressure.tire_fr')}</div>
          <div className={Style.tireCold}>{t('common:pressure.cold')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.rear_min_cold_pressure}
          />
          <div className={Style.tireHot}>{t('common:pressure.hot')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.rear_pressure}
          />
        </div>
        <div className={`${Style.tire} ${Style.tireRl}`}>
          <div className={Style.tireTitle}>{t('common:pressure.tire_rl')}</div>
          <div className={Style.tireCold}>{t('common:pressure.cold')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.front_min_cold_pressure}
          />
          <div className={Style.tireHot}>{t('common:pressure.hot')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.front_pressure}
          />
        </div>
        <div className={`${Style.tire} ${Style.tireRr}`}>
          <div className={Style.tireTitle}>{t('common:pressure.tire_rr')}</div>
          <div className={Style.tireCold}>{t('common:pressure.cold')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.rear_min_cold_pressure}
          />
          <div className={Style.tireHot}>{t('common:pressure.hot')} :</div>
          <PressureDisplay
            pressureValue={pressureRecommendation.rear_pressure}
          />
        </div>
      </div>
    </Flex>
  )
}

PressureRecommendation.defaultProps = {
  pressureRecommendation: {},
}

PressureRecommendation.propTypes = {
  pressureRecommendation: PropTypes.shape({
    front_min_cold_pressure: PropTypes.number,
    rear_min_cold_pressure: PropTypes.number,
    front_pressure: PropTypes.number,
    rear_pressure: PropTypes.number,
  }),
}

export default PressureRecommendation
