import axios from 'axios'
import { getI18n } from 'react-i18next'

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const getAllSearch = (token, search) =>
  axios.get(`${apiPortalUrl}/search?search=${search}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Languages': getI18n().language.slice(0, 2),
    },
  })

export default getAllSearch
