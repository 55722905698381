const getPositionLabel = (position) => {
  switch (position) {
    case 0:
      return 'front_right_date'
    case 1:
      return 'front_left_date'
    case 2:
      return 'rear_right_date'
    case 3:
      return 'rear_left_date'
    default:
      return ''
  }
}

export default getPositionLabel
