import axios from 'axios'
import { getI18n } from 'react-i18next'

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const getRallyDimension = (token, brandId, modelId, groupID) =>
  axios.get(
    `${apiPortalUrl}/rally-brands/${brandId}/rally-models/${modelId}/rally-groups/${groupID}/rally-dimensions`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Accept-Language': getI18n().language.slice(0, 2),
      },
    }
  )

export default getRallyDimension
