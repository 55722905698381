import React, { useContext, useEffect } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TYPE from 'constants/catalogs'
import { getCatalogsItems, getCatalogsTags } from 'services/catalogService'
import Filters from 'components/Filters/Filters'
import { NotificationContext } from 'contexts/NotificationContext'
import TireEssentialList from './TireEssentialList/TireEssentialList'
import TireEssentialDetail from '../TireEssentialDetail/TireEssentialDetail'

const TireEssential = () => {
  const [tireEssentials, setTireEssentials] = useStateIfMounted({
    elements: [],
    tags: [],
  })
  const [tireEssentialSelected, setTireEssentialSelected] =
    useStateIfMounted(null)
  const [tagSelected, setTagSelected] = useStateIfMounted(null)
  const [isViewDetail, setIsViewDetail] = useStateIfMounted(false)
  const { openSnackBar } = useContext(NotificationContext)

  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslation('tire_essential')
  const token = localStorage.getItem('access_token')

  const getTags = async () => {
    const { data } = await getCatalogsTags(token)
    return data
  }
  const getTireEssentials = async () => {
    const {
      data: { catalog_items },
    } = await getCatalogsItems(token, TYPE.YOUTUBE)
    return catalog_items
  }

  useEffect(() => {
    const fetchTireEssentialsAndTags = async () => {
      try {
        Promise.all([getTags(), getTireEssentials()]).then((values) => {
          const listCatalogsTagsWithoutDuplicate = [
            ...new Set(
              values[1].flatMap((tireEssential) => tireEssential.catalog_tags)
            ),
          ]
          const tagsVideo = values[0].filter((tag) =>
            listCatalogsTagsWithoutDuplicate.includes(tag.id)
          )
          setTireEssentials({
            elements: values[1],
            tags: tagsVideo,
          })
          if (id) {
            setIsViewDetail(true)
            setTireEssentialSelected(values[1].find((v) => v.id === Number(id)))
          }
          setTagSelected(tagsVideo[0])
        })
      } catch (error) {
        openSnackBar(
          `${t('tire_essential:notification.get_data.error')}: ${
            error.message
          }`,
          'error'
        )
      }
    }
    if (tireEssentials.elements.length === 0) {
      fetchTireEssentialsAndTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  function goToDetail(tireId) {
    history.push(`/tire-essentials/${tireId}`)
    setIsViewDetail(true)
    setTireEssentialSelected(
      tireEssentials.elements.find((v) => v.id === tireId)
    )
  }

  if (!tagSelected) {
    return null
  }

  const onChangeFilter = (tag) => {
    setTagSelected(tag)
    if (tireEssentialSelected) {
      setTireEssentialSelected(null)
    }
    setIsViewDetail(false)
  }

  return (
    <>
      <Filters
        goBack={history.goBack}
        noCalendar
        onChange={onChangeFilter}
        context="catalog"
        items={tireEssentials.tags}
        idCurrent={tagSelected.id}
      />
      <div className="content">
        {!isViewDetail && tireEssentials.elements.length !== 0 ? (
          <>
            <h1>{t('tire_essential:title')}</h1>
            <TireEssentialList
              tireEssentials={tireEssentials.elements.filter((tire) =>
                tire.catalog_tags.includes(tagSelected.id)
              )}
              tags={tireEssentials.tags}
              handleClick={goToDetail}
            />
          </>
        ) : (
          isViewDetail && (
            <TireEssentialDetail
              tireEssentials={tireEssentials.elements.filter((tire) =>
                tire.catalog_tags.includes(tagSelected.id)
              )}
              tireEssential={tireEssentialSelected}
              tagsName={tireEssentialSelected.catalog_tags.map(
                (catalogTag) =>
                  tireEssentials.tags.find((tag) => tag.id === catalogTag).name
              )}
              tags={tireEssentials.tags}
              handleClick={goToDetail}
            />
          )
        )}
      </div>
    </>
  )
}

export default TireEssential
