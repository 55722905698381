import React from 'react'
import { ReactComponent as IconNextArrow } from 'img/icon_arrow_next.svg'

function NextArrow({ className, onClick, customStyle }) {
  return (
    <IconNextArrow
      className={className}
      onClick={onClick}
      style={{ ...customStyle, display: 'block' }}
    />
  )
}

export default NextArrow
