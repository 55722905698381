import React from 'react'
import Switch from 'react-switch'

import { ReactComponent as IconAlert } from 'img/icon_alert.svg'
import { ReactComponent as IconUilTemperature } from 'img/icon_uil_temperature.svg'
import { PRIMARY_COLOR, BLUE_LIGHT_COLOR } from 'constants/colors'

const ChoicePressOrTemp = ({ checked, setChecked }) => {
  return (
    <>
      <IconAlert />
      <label style={{ margin: '0 8px' }}>
        <Switch
          onChange={(e) => setChecked(e)}
          checked={checked}
          onColor={PRIMARY_COLOR}
          offColor={BLUE_LIGHT_COLOR}
          onHandleColor={BLUE_LIGHT_COLOR}
          offHandleColor={PRIMARY_COLOR}
          uncheckedIcon={false}
          checkedIcon={false}
          handleDiameter={15}
          height={15}
          width={30}
        />
      </label>
      <IconUilTemperature />
    </>
  )
}

export default ChoicePressOrTemp
