import React from 'react'
import Stages from 'components/Stages/Stages'
import Tags from '../../Tags/Tags'

const FiltersItems = ({ items, context, idCurrent, onChange }) => {
  return context === 'catalog' ? (
    <Tags tags={items} idCurrent={idCurrent} onChange={onChange} />
  ) : (
    <Stages runs={items} idRunCurrent={idCurrent} onChange={onChange} />
  )
}

export default FiltersItems
