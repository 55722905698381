import { format, fromUnixTime } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ClassNames from 'classnames'
import { ReactComponent as IconGarage } from 'img/icon_garage.svg'
import { ReactComponent as IconRuns } from 'img/icon_runs.svg'
import { ReactComponent as IconSearch } from 'img/icon_search.svg'
import { ReactComponent as IconTire } from 'img/icon_tire.svg'
import getAllSearch from 'services/otherService'
import { NotificationContext } from 'contexts/NotificationContext'
import Style from './Autocomplete.module.scss'

const Autocomplete = () => {
  const [value, setValue] = useState('')
  const [options, setOptions] = useState({
    catalogItems: [],
    rallyCars: [],
    rallySessions: [],
  })
  const [showOptions, setShowOptions] = useState(false)
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation('common')

  const handleSearch = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    const fetchAllSearch = async () => {
      const token = localStorage.getItem('access_token')
      setShowOptions(true)
      try {
        const { data } = await getAllSearch(token, value)
        setOptions({
          catalogItems: data.catalog_items,
          rallyCars: data.rally_cars,
          rallySessions: data.rally_sessions,
        })
      } catch (error) {
        openSnackBar(
          `${t('common:autocomplete.notification.get_data.error')}: ${
            error.message
          }`,
          'error'
        )
      }
    }
    if (value !== '') {
      fetchAllSearch()
    } else {
      setShowOptions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div
      className={ClassNames(Style.autocomplete, {
        [Style.open]: showOptions,
      })}
    >
      <div className={Style.searchBar}>
        <IconSearch className={Style.iconSearch} />
        <input
          type="text"
          onChange={handleSearch}
          value={value}
          placeholder={t('common:autocomplete.placeholder')}
        />
      </div>
      {showOptions && (
        <div className={Style.options}>
          <div className={Style.section}>
            <div className={Style.title}>
              &gt; {t('common:autocomplete.section.runs')}
            </div>
            {options.rallySessions.length === 0 ? (
              <div className={Style.noData}>{t('common:no_data')}</div>
            ) : (
              options.rallySessions.map((session) => {
                return (
                  <Link
                    key={session.id}
                    to={`/session/${session.id}/run/${session.rally_run_ids[0]}`}
                  >
                    <IconRuns width={12} height={9} /> {session.name} -{' '}
                    {format(fromUnixTime(session.date), 'dd.MM.yyyy')}
                  </Link>
                )
              })
            )}
          </div>
          <div className={Style.section}>
            <div className={Style.title}>
              &gt; {t('common:autocomplete.section.vehicles')}
            </div>
            {options.rallyCars.length === 0 ? (
              <div className={Style.noData}>{t('common:no_data')}</div>
            ) : (
              options.rallyCars.map((car) => {
                const { name, rally_brand } =
                  car.rally_dimension.rally_group.rally_model
                return (
                  <Link key={car.id} to={`/vehicles/${car.id}`}>
                    <IconGarage width={19} height={8} /> {name} -{' '}
                    {rally_brand?.name}
                  </Link>
                )
              })
            )}
          </div>
          <div className={Style.section}>
            <div className={Style.title}>
              &gt; {t('common:autocomplete.section.catalog')}
            </div>
            {options.catalogItems.length === 0 ? (
              <div className={Style.noData}>{t('common:no_data')}</div>
            ) : (
              options.catalogItems.map((catalog) => {
                return (
                  <Link key={catalog.id} to={`/catalogs/${catalog.id}`}>
                    <IconTire width={11} height={12} /> {catalog.name}
                  </Link>
                )
              })
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Autocomplete
