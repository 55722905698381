import React from 'react'
import Slider from 'react-slick'
import ClassNames from 'classnames'
import NextArrow from 'components/NextArrow'
import PrevArrow from 'components/PrevArrow'
import Style from './Tags.module.scss'

const Tags = ({ tags, idCurrent, onChange }) => {
  const handleChange = (id) => {
    onChange(id)
  }

  return (
    <Slider
      dots={false}
      slidesToShow={4}
      infinite={false}
      focusOnSelect={false}
      slidesToScroll={4}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      className={Style.sliderTags}
    >
      {tags.map((tag) => {
        return (
          <div
            className={ClassNames(Style.tag, {
              [Style.active]: tag.id === idCurrent,
            })}
            key={tag.id}
            onClick={() => handleChange(tag)}
            onKeyPress={() => handleChange(tag)}
            role="button"
            label="tags"
          >
            <div className={Style.slide}>
              <div>{tag.name}</div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default Tags
