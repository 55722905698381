import React from 'react'
import { PRESSURE } from 'constants/tire'
import PressureDisplay from 'components/PressureDisplay'
import TemperatureDisplay from 'components/TemperatureDisplay'
import Style from './TirePressureValue.module.scss'

const TirePressuresValue = ({ value, context }) => {
  if (!value.value) {
    return null
  }
  return (
    <b className={Style.pressureNumber} style={{ color: value.color }}>
      {context === PRESSURE ? (
        <PressureDisplay pressureValue={value.value} />
      ) : (
        <TemperatureDisplay temperatureValue={value.value} />
      )}
    </b>
  )
}

export default TirePressuresValue
