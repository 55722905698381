import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import ChoicePressOrTemp from 'components/ChoicePressOrTemp'
import { TimeContext } from 'contexts/TimeContext'
import formatMinSec from 'utils/formatMinSec'
import Style from './RunsRallyRealTimePressure.module.scss'
import RunsRallyRealTimePressureContent from './RunsRallyRealTimePressureContent'

const RunsRallyTimePressure = ({
  tirePressures,
  isVisible,
  tireBoundaries,
}) => {
  const [checked, setChecked] = useState(false)
  const { timeSlider, time } = useContext(TimeContext)
  const { t } = useTranslation(['run', 'common'])
  return (
    <div className={Style.card}>
      {!isVisible ? (
        <Flex
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding="1rem"
        >
          <h3>{t('run:real_time_pressure:title')}</h3>
          <span className={Style.noData}>{t('common:no_data')}</span>
        </Flex>
      ) : (
        <Flex container padding="35px 20px" justifyContent="space-between">
          <Flex container flexDirection="column" justifyContent="space-between">
            <h3>
              {t('run:real_time_pressure:title_p1')} <br />
              {t('run:real_time_pressure:title_p1_end')}
            </h3>
            <div>
              at <b>{formatMinSec(timeSlider)}</b>
            </div>
            <div>
              <ChoicePressOrTemp
                checked={checked}
                setChecked={(value) => setChecked(value)}
              />
            </div>
          </Flex>
          <RunsRallyRealTimePressureContent
            time={time}
            tirePressures={tirePressures}
            checked={checked}
            tireBoundaries={tireBoundaries}
          />
        </Flex>
      )}
    </div>
  )
}

export default RunsRallyTimePressure
