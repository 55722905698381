import React, { useState, useContext } from 'react'
import TextField from 'components/TextField'
import { NotificationContext } from 'contexts/NotificationContext'
import { updateTitleRallySession } from 'services/rallySessionService'
import { useTranslation } from 'react-i18next'
import getDefaultNameRun from '../../../utils/getDefaultNameRun'

const RunsRallyInfosTitle = ({ name, startDate, sessionId }) => {
  const { openSnackBar } = useContext(NotificationContext)
  const [titleRally, setTitleRally] = useState({
    value: name,
    editable: false,
  })
  const { t } = useTranslation('run')

  const handleChange = () => (event) => {
    setTitleRally({
      editable: true,
      value: event.target.value,
    })
  }

  const handleKeyDown = () => async (event) => {
    if (event.keyCode === 13) {
      setTitleRally({
        value: titleRally.value,
        editable: false,
      })
      try {
        const token = localStorage.getItem('access_token')

        await updateTitleRallySession(token, sessionId, titleRally.value)
        openSnackBar(t('run:info.notification.title.success'), 'success')
      } catch (error) {
        openSnackBar(
          `${t('run:info.notification.title.error')}: ${error.message}`,
          'error'
        )
      }
    }
  }

  const handleChangeEdit = () => {
    setTitleRally({
      value: titleRally.value,
      editable: true,
    })
  }

  return (
    <TextField
      type="text"
      value={titleRally.value ? titleRally.value : getDefaultNameRun(startDate)}
      editable={titleRally.editable}
      handleChange={handleChange}
      handleKeyDown={handleKeyDown}
      handleChangeEdit={handleChangeEdit}
    />
  )
}

export default RunsRallyInfosTitle
