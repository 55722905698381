import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import Style from './DropdownItem.module.scss'

const DropdownItem = ({ isLink, label, link, handleClick, style }) => {
  const item = isLink ? (
    <Link to={link}>{label}</Link>
  ) : (
    <span
      onClick={handleClick}
      onKeyPress={handleClick}
      style={style}
      role="button"
    >
      {label}
    </span>
  )

  return <span className={Style.link}>{item}</span>
}

DropdownItem.defaultProps = {
  isLink: false,
  link: '',
  handleClick: () => {},
  style: {},
}

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  link: PropTypes.string,
  handleClick: PropTypes.func,
  style: PropTypes.shape({
    fontWeight: PropTypes.string,
  }),
}

export default DropdownItem
