import React from 'react'
import Slider from 'infinite-react-carousel'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import Image01 from 'img/image_01.jpg'
import Logo from 'img/logo_michelin.png'
import IconHomepage from 'img/icon_homepage.png'
import Auth from 'services/Auth'

import Style from './Login.module.scss'

const auth = new Auth()

const Login = () => {
  const { t } = useTranslation(['login', 'button'])
  return (
    <Flex container className={Style.container}>
      <Flex
        flexDirection="column"
        container
        alignItems="center"
        className={Style.panelLeft}
      >
        <img src={Logo} alt="logo" className={Style.imgLogo} />
        <img src={IconHomepage} alt="logo" className={Style.imgIcon} />
        <h1>
          {t('login:title')} <br /> {t('login:title_end')}
        </h1>
        <button type="button" onClick={auth.login.bind(this)}>
          <span>{t('button:sign_in')}</span>
        </button>
      </Flex>
      <div className={Style.panelRight}>
        <Slider dots autoplay={false} arrows={false} className="sliderCustom">
          <div style={{ height: '100vh' }}>
            <img src={Image01} alt="carrousel_image_01" />
          </div>
          <div>
            <img src={Image01} alt="carrousel_image_02" />
          </div>
        </Slider>
      </div>
    </Flex>
  )
}

export default Login
