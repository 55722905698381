import React, { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useTranslation } from 'react-i18next'
import Style from './RetailersMap.module.scss'
import MarkerSVG from '../../../../img/marker.svg'
import { PRIMARY_COLOR, YELLOW_COLOR } from '../../../../constants/colors'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX

const RetailersMap = ({ data, retailerCoordinates, resetCoordinates }) => {
  const mapContainerRef = useRef(null)
  const [displayedMap, setDisplayedMap] = useStateIfMounted(null)
  const { t } = useTranslation('homepage')

  const getDescription = (retailer) => {
    return `<h2 style=text-align:center>${t(
      'homepage:retailers.map_detail.title'
    )}</h2><b>${t('homepage:retailers.map_detail.country')}:</b> ${
      retailer.country
    }<br /><b>${t('homepage:retailers.map_detail.name')}:</b> ${
      retailer.name
    }</br><b>${t('homepage:retailers.map_detail.type')}:</b> ${
      retailer.type
    }</br><b>${t('homepage:retailers.map_detail.mail')}:</b> <a href=mailto:${
      retailer.mail
    }>${retailer.mail}</a></br><b>${t(
      'homepage:retailers.map_detail.contact'
    )}:</b>${retailer.contact}</br><b>${t(
      'homepage:retailers.map_detail.address'
    )}:</b> ${retailer.adress}`
  }

  // Initialize map when component mounts
  useEffect(() => {
    const initializeMap = ({ setMap, ref }) => {
      if (!ref.current) {
        return
      }

      const map = new mapboxgl.Map({
        container: ref.current || '',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [2.3488, 48.8534],
        zoom: 3,
      })

      const img = new Image(14, 20)
      img.onload = () => map.addImage('marker', img, { sdf: true })
      img.src = MarkerSVG

      map.on('load', function loadMap() {
        map.addSource('retailers', {
          type: 'geojson',
          data,
        })

        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'retailers',
          layout: {
            'icon-image': 'marker',
            'icon-allow-overlap': true,
          },
          paint: {
            'icon-color': PRIMARY_COLOR,
            'icon-halo-color': YELLOW_COLOR,
            'icon-halo-width': 2,
          },
        })

        map.on('click', 'points', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice()
          const description = getDescription(e.features[0].properties)

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map)
        })

        map.on('click', 'points', (e) => {
          let coordinates = [0, 0]
          if (e?.features[0]?.geometry !== undefined) {
            coordinates = e.features[0].geometry
          }
          const lat = coordinates[1] - 5
          map.flyTo({ center: [coordinates[0], lat] })
        })

        map.on('mouseenter', 'points', () => {
          map.getCanvas().style.cursor = 'pointer'
        })

        map.on('mouseleave', 'points', () => {
          map.getCanvas().style.cursor = ''
        })
      })

      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        })
      )

      setMap(map)
    }

    if (!displayedMap) {
      initializeMap({ setMap: setDisplayedMap, ref: mapContainerRef })
    } else if (retailerCoordinates.length !== 0) {
      displayedMap.flyTo({
        center: retailerCoordinates,
        essential: true,
        zoom: 7,
      })
      const retailer = data.features.find(
        (feature) => feature.geometry.coordinates === retailerCoordinates
      )
      const description = getDescription(retailer.properties)
      new mapboxgl.Popup()
        .setLngLat(retailerCoordinates)
        .setHTML(description)
        .addTo(displayedMap)
      resetCoordinates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedMap, data, retailerCoordinates])

  return <div ref={mapContainerRef} className={Style.mapContainer} />
}

export default RetailersMap
