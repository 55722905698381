import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import OptimalRangeOperation from 'components/OptimalRangeOperation/OptimalRangeOperation'
import CamberAngleItem from 'components/CamberAngleItem/CamberAngleItem'
import Style from './VehiclesSliderItem.module.scss'

const VehiclesSliderItem = ({ vehicle }) => {
  const history = useHistory()
  const { t } = useTranslation(['button', 'vehicles', 'common'])

  function goToVehiculeDetails(e, id) {
    e.preventDefault()

    history.push(`vehicles/${id}`)
  }

  function goToRunDetails(e, id) {
    e.preventDefault()
    history.push(`runs`)
  }

  return (
    <>
      <Flex
        container
        flexDirection="row"
        justifyContent="space-between"
        flex="initial"
      >
        <div className={Style.vehicle}>
          <div className={Style.brand}>
            {vehicle.rally_dimension.rally_group.rally_model.rally_brand.name}
          </div>
          <div className={Style.model}>
            {vehicle.rally_dimension.rally_group.rally_model.name}
          </div>
          <div className={Style.dimensions}>
            <b>{t('common:tire.av')} :</b>{' '}
            {vehicle.rally_dimension.front_dimension}
          </div>
          <div>
            <b>{t('common:tire.ar')} :</b>{' '}
            {vehicle.rally_dimension.rear_dimension}
          </div>
        </div>
        <div className={Style.camberAngle}>
          <div className={Style.camberAnglTitle}>
            {t('vehicles:camber_angle_title')} :
          </div>
          <Flex
            container
            flexDirection="column"
            height="75%"
            justifyContent="space-around"
            alignItems="center"
          >
            <CamberAngleItem
              title={t('common:tire.front')}
              value={vehicle.front_camber}
            />
            <CamberAngleItem
              title={t('common:tire.rear')}
              value={vehicle.rear_camber}
            />
          </Flex>
        </div>
      </Flex>
      <OptimalRangeOperation
        front_optimal_range={vehicle.front_optimal_range_operation}
        rear_optimal_range={vehicle.rear_optimal_range_operation}
        className={Style.optimalRangeOperation}
      />
      <Flex container flex="initial" className={Style.vehicleButtons}>
        <button
          className={Style.buttonEdit}
          type="button"
          onClick={(e) => goToVehiculeDetails(e, vehicle.id)}
        >
          {t('button:edit')}
        </button>
        <button
          type="button"
          className={Style.buttonShow}
          onClick={(e) => goToRunDetails(e, vehicle.id)}
        >
          {t('button:show_runs')}
        </button>
      </Flex>
    </>
  )
}

VehiclesSliderItem.defaultProps = {
  vehicle: {},
}

VehiclesSliderItem.propTypes = {
  vehicle: PropTypes.shape({
    front_camber: PropTypes.number,
    rear_camber: PropTypes.number,
    front_optimal_range: PropTypes.number,
    rear_optimal_range: PropTypes.number,
    rally_dimension: PropTypes.shape({
      front_dimension: PropTypes.string,
      rear_dimension: PropTypes.string,
      rally_group: PropTypes.shape({
        rally_model: PropTypes.shape({
          name: PropTypes.string,
          rally_brand: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
}

export default VehiclesSliderItem
