import axios from 'axios'
import queryString from 'query-string'
import { getUnixTime } from 'date-fns'
import { getI18n } from 'react-i18next'

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const query = (from, to) => {
  return queryString.stringify(
    {
      from: isNaN(getUnixTime(from)) ? null : getUnixTime(from),
      to: isNaN(getUnixTime(to)) ? null : getUnixTime(to),
    },
    {
      skipNull: true,
    }
  )
}
const findAll = (token, startingPeriodRange, finishPeriodRange) =>
  axios.get(
    `${apiPortalUrl}/home/rally?${query(
      startingPeriodRange,
      finishPeriodRange
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Accept-Language': getI18n().language.slice(0, 2),
      },
    }
  )

export default findAll
