import React from 'react'
import Chart from 'react-apexcharts'
import {
  PRIMARY_COLOR,
  ARG_COLOR,
  YELLOW_COLOR,
  ARD_COLOR,
  RED_LIGHT_COLOR,
  ORANGE_LIGHT_COLOR,
  GREEN_LIGHT_COLOR,
  BLUE_LIGHT_COLOR,
} from 'constants/colors'
import { PRESSURE } from 'constants/tire'
import {
  PREFERENCE_PRESSURE_DEFAULT,
  PREFERENCE_TEMPERATURE_DEFAULT,
} from 'constants/preferences'
import { convertBarTopsi } from 'utils/convertPressure'
import { convertCelciusToF } from 'utils/convertTemperature'
import getSecondes from 'utils/getSecondes'
import formatHoursMinSec from 'utils/formatHoursMinSec'

const pressureDisplay = (
  pressureValue,
  pressurePreference,
  nbAfterComma = 2,
  unitToDisplay = ''
) => {
  return pressurePreference === PREFERENCE_PRESSURE_DEFAULT
    ? `${pressureValue.toFixed(nbAfterComma)}${unitToDisplay}`
    : `${convertBarTopsi(pressureValue).toFixed(nbAfterComma)}${unitToDisplay}`
}

const temperatureDisplay = (temperatureValue, temperaturePreference) => {
  return temperaturePreference === PREFERENCE_TEMPERATURE_DEFAULT
    ? temperatureValue
    : convertCelciusToF(temperatureValue).toFixed(0)
}

const formatedSeries = (values, position, context) => {
  return values
    .filter((d) => d.position === position)
    .sort((a, b) => a.trame_date - b.trame_date)
    .map((d) => {
      const serie = {}
      serie.x = d.trame_date
      serie.y = context === PRESSURE ? d.pressure : d.temperature
      return serie
    })
}

const ChartLines = ({ dataRun, context, preferences }) => {
  const serieAVG = formatedSeries(dataRun, 0, context)
  const serieARG = formatedSeries(dataRun, 1, context)
  const serieAVD = formatedSeries(dataRun, 2, context)
  const serieARD = formatedSeries(dataRun, 3, context)

  const options = {
    chart: {
      id: 'pressure-evolution',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [PRIMARY_COLOR, ARG_COLOR, YELLOW_COLOR, ARD_COLOR],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: true,
        color: PRIMARY_COLOR,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 700,
        },
        format: 'mm:ss',
        formatter(value) {
          const dateInSecondes = getSecondes(value)
          return formatHoursMinSec(dateInSecondes)
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => {
          if (value) {
            const unitToDisplay =
              preferences.pressure === PREFERENCE_PRESSURE_DEFAULT ? 'b' : 'p'

            return context === PRESSURE
              ? pressureDisplay(value, preferences.pressure, 1, unitToDisplay)
              : temperatureDisplay(value, preferences.temperature)
          }
          return null
        },
      },
    },
    yaxis: {
      min: () => (context === PRESSURE ? 1.2 : 20),
      max: () => (context === PRESSURE ? 2.7 : 110),
      axisBorder: {
        show: true,
        color: PRIMARY_COLOR,
      },
      tickAmount: 5,
      labels: {
        style: {
          colors: [PRIMARY_COLOR],
          fontSize: '10px',
          fontWeight: 700,
        },
        formatter: (value) => {
          if (value) {
            const unitToDisplay =
              preferences.pressure === PREFERENCE_PRESSURE_DEFAULT ? 'b' : 'p'

            return context === PRESSURE
              ? pressureDisplay(
                  value,
                  preferences.pressure,
                  preferences.pressure === PREFERENCE_PRESSURE_DEFAULT ? 1 : 0,
                  unitToDisplay
                )
              : temperatureDisplay(value, preferences.temperature)
          }
          return null
        },
      },
    },
  }
  const series = [
    {
      name: 'AVG',
      data: serieAVG,
    },
    {
      name: 'ARG',
      data: serieARG,
    },
    {
      name: 'AVD',
      data: serieAVD,
    },
    {
      name: 'ARD',
      data: serieARD,
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          width: 'calc(100% - 55px)',
          height: '263px',
          left:
            context === PRESSURE
              ? preferences.pressure === PREFERENCE_PRESSURE_DEFAULT
                ? '47px'
                : '44px'
              : '45px',
          bottom: '43px',
          backgroundImage:
            context === PRESSURE
              ? `linear-gradient(${RED_LIGHT_COLOR} 27%, ${ORANGE_LIGHT_COLOR} 27%, ${ORANGE_LIGHT_COLOR} 33%, ${GREEN_LIGHT_COLOR} 33%,${GREEN_LIGHT_COLOR} 52%, ${ORANGE_LIGHT_COLOR} 52%, ${ORANGE_LIGHT_COLOR} 73%, ${RED_LIGHT_COLOR} 73%)`
              : `linear-gradient(${RED_LIGHT_COLOR} 18%, ${ORANGE_LIGHT_COLOR} 18%, ${ORANGE_LIGHT_COLOR} 34%, ${GREEN_LIGHT_COLOR} 34%,${GREEN_LIGHT_COLOR} 94%, ${GREEN_LIGHT_COLOR} 94%, ${GREEN_LIGHT_COLOR} 94%, ${BLUE_LIGHT_COLOR} 94%)`,
        }}
      />
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height={320}
      />
    </div>
  )
}

export default ChartLines
