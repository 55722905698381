import axios from 'axios'
import queryString from 'query-string'
import { getUnixTime } from 'date-fns'
import { getI18n } from 'react-i18next'

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const query = (from, to, page, limit, order, orderby) => {
  return queryString.stringify(
    {
      from: isNaN(getUnixTime(from)) ? null : getUnixTime(from),
      to: isNaN(getUnixTime(to)) ? null : getUnixTime(to),
      page,
      limit,
      order,
      orderby,
    },
    {
      skipNull: true,
    }
  )
}

const findAll = (
  token,
  startingPeriodRange,
  finishPeriodRange,
  page,
  limit,
  order,
  orderby
) =>
  axios.get(
    `${apiPortalUrl}/rally-sessions?${query(
      startingPeriodRange,
      finishPeriodRange,
      page,
      limit,
      order,
      orderby
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Accept-Language': getI18n().language.slice(0, 2),
      },
    }
  )

export default findAll
