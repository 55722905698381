import React from 'react'
import { ReactComponent as IconEdit } from 'img/icon_edit.svg'

const TextField = ({
  type,
  label,
  value,
  editable,
  handleChange,
  handleKeyDown,
  handleChangeEdit,
}) => {
  return (
    <label>
      {label && `${label} :`}
      {editable ? (
        <input
          type={type}
          value={value}
          onChange={handleChange(label)}
          onKeyDown={handleKeyDown(label)}
        />
      ) : (
        <span>
          {value}
          {editable !== undefined && !editable && (
            <IconEdit onClick={() => handleChangeEdit(label)} />
          )}
        </span>
      )}
    </label>
  )
}

export default TextField
