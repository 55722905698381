import { ROUTE_CALLBACK } from './routes'

// idTokenPayload should match the one used in auth0 rules (context.accessToken)
export const AUTH0_ID_TOKEN_PAYLOAD =
  'https://michelin-trackconnect-dev.openium.fr/role'

// CHANGE : auth0 configuration
export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  callbackUrl: ROUTE_CALLBACK,
}
