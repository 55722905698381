import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { NotificationProvider } from './contexts/NotificationContext'
import { PreferencesProvider } from './contexts/PreferencesContext'

import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'

ReactDOM.render(
  <PreferencesProvider>
    <NotificationProvider>
      <Router>
        <App />
      </Router>
    </NotificationProvider>
  </PreferencesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
