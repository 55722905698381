import axios from 'axios'
import { getI18n } from 'react-i18next'

const apiUrl = process.env.REACT_APP_API_URL

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const getStageRunsForSession = (session) => {
  if ('rally_runs' in session) {
    return session.rally_runs.filter((elt) => {
      return elt.rolling_type === 1 // Stage
    })
  }
  return []
}

const getRallySession = (token, sessionId) =>
  axios.get(`${apiPortalUrl}/rally-sessions/${sessionId}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

const sendAnalysisRequest = (token, sessionId, text) =>
  axios.post(
    `${apiPortalUrl}/rally-sessions/${sessionId}/analysis-request`,
    { message: text },
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Accept-Language': getI18n().language.slice(0, 2),
      },
    }
  )

const updateTitleRallySession = (token, sessionId, name) =>
  axios.put(
    `${apiUrl}/rally-sessions/${sessionId}`,
    { name },
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Accept-Language': getI18n().language.slice(0, 2),
      },
    }
  )

export {
  getRallySession,
  sendAnalysisRequest,
  updateTitleRallySession,
  getStageRunsForSession,
}
