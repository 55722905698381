import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import OptimalRangeOperation from 'components/OptimalRangeOperation/OptimalRangeOperation'

import getRallyDimension from 'services/rallyDimensionService'
import { NotificationContext } from 'contexts/NotificationContext'
import Style from './VehicleInfos.module.scss'
import VehicleInfosDimensionsOfTyre from './VehicleInfosDimensionsOfTyre'

const VehicleInfos = ({
  front_optimal_range_operation,
  rear_optimal_range_operation,
  rally_dimension,
  receiver,
  dimensions,
  handleChangeDimensionId,
  handleChangeDimensions,
  dimensionId,
}) => {
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation('vehicle')

  useEffect(() => {
    const fetchDimensions = async () => {
      try {
        const token = localStorage.getItem('access_token')
        const response = await getRallyDimension(
          token,
          rally_dimension.rally_group.rally_model.rally_brand.id,
          rally_dimension.rally_group.rally_model.id,
          rally_dimension.rally_group.id
        )
        handleChangeDimensions(response.data)
      } catch (error) {
        openSnackBar(
          `${t('vehicle.notification.get_dimension.error')}: ${error.message}`,
          'error'
        )
      }
    }
    fetchDimensions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rally_dimension])

  return (
    <Flex
      container
      flexDirection="column"
      justifyContent="space-between"
      className={Style.detail}
      margin="3rem 40px 0 0"
      flex="1 1 0"
      padding="0"
    >
      <div>
        <b>{t('vehicle:brand')} :</b>
        <span className={Style.label}>
          {rally_dimension.rally_group.rally_model.rally_brand.name}
        </span>
      </div>
      <div>
        <b>{t('vehicle:model')} :</b>
        <span className={Style.label}>
          {rally_dimension.rally_group.rally_model.name}
        </span>
      </div>
      <div>
        <b>{t('vehicle:group')} :</b>{' '}
        <span className={Style.label}>{rally_dimension.rally_group.name}</span>
      </div>
      {dimensions.length !== 0 && (
        <VehicleInfosDimensionsOfTyre
          dimensions={dimensions}
          handleChangeDimensionId={handleChangeDimensionId}
          dimension={dimensions.find((d) => d.id === dimensionId)}
        />
      )}
      <div>
        <b>{t('vehicle:receiver')} :</b>{' '}
        <span className={Style.label}>{receiver.name}</span>
      </div>
      <div className={`${Style.card} ${Style.optimalRangeOperation}`}>
        <OptimalRangeOperation
          front_optimal_range={front_optimal_range_operation}
          rear_optimal_range={rear_optimal_range_operation}
        />
      </div>
    </Flex>
  )
}

VehicleInfos.defaultProps = {
  rally_dimension: {},
  receiver: [],
  front_optimal_range_operation: null,
  rear_optimal_range_operation: null,
  dimensions: [],
  handleChangeDimensionId: () => {},
  handleChangeDimensions: () => {},
  dimensionId: null,
}

VehicleInfos.propTypes = {
  rally_dimension: PropTypes.shape({
    front_dimension: PropTypes.string,
    rear_dimension: PropTypes.string,
    rally_group: PropTypes.shape({
      rally_model: PropTypes.shape({
        name: PropTypes.string,
        rally_brand: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
  }),
  receiver: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  front_optimal_range_operation: PropTypes.number,
  rear_optimal_range_operation: PropTypes.number,
  dimensions: PropTypes.arrayOf(PropTypes.shape({})),
  dimensionId: PropTypes.number,
  handleChangeDimensions: PropTypes.func,
  handleChangeDimensionId: PropTypes.func,
}

export default VehicleInfos
