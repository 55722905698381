import {
  BLUE_LIGHT_COLOR,
  GREEN_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
} from 'constants/colors'

/**
 *
 * @param value string
 * @returns string
 */
const getColorTemp = (value) => {
  if (!value) {
    return ''
  }
  if (value <= 25) {
    return BLUE_LIGHT_COLOR
  }
  if (value > 25 && value <= 80) {
    return GREEN_COLOR
  }
  if (value > 80 && value <= 95) {
    return ORANGE_COLOR
  }
  if (value > 95) {
    return RED_COLOR
  }

  return ''
}

export default getColorTemp
