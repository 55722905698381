import React, { createContext } from 'react'
import Snackbar from 'components/Snackbar/Snackbar'
import useSnackbar from 'hooks/useSnackbar'

const NotificationContext = createContext({})

const NotificationProvider = ({ children }) => {
  const { isActive, message, severity, openSnackBar } = useSnackbar()

  return (
    <NotificationContext.Provider value={{ openSnackBar }}>
      <>
        {children}
        <Snackbar isActive={isActive} severity={severity} message={message} />
      </>
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
