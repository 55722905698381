import React from 'react'
import Map from 'components/Map/Map'

const RunsRallyInfosMap = ({
  rallyCarLocations,
  tirePressures,
  tireBoundaries,
}) => {
  if (rallyCarLocations.length === 0) {
    return null
  }

  return (
    <Map
      data={rallyCarLocations}
      tirePressures={tirePressures}
      tireBoundaries={tireBoundaries}
    />
  )
}

export default RunsRallyInfosMap
