import React from 'react'
import Header from './Header/Header'
import Menu from './Menu/Menu'

import Style from './Layout.module.scss'

const Layout = ({ children, logout }) => {
  return (
    <div className={Style.gridContainer}>
      <Header logout={logout} />
      <Menu />
      <div className={Style.main}>{children}</div>
    </div>
  )
}

export default Layout
