import React from 'react'
import PropTypes from 'prop-types'
import Style from './Avatar.module.scss'

const Avatar = ({ url, size }) => {
  return (
    <img
      src={url || 'https://via.placeholder.com/55?text=avatar'}
      width={size}
      height={size}
      alt="avatar"
      className={Style.avatar}
    />
  )
}

Avatar.defaultProps = {
  url: '',
  size: null,
}

Avatar.propTypes = {
  url: PropTypes.string,
  size: PropTypes.number,
}

export default Avatar
