import React, { useState, useEffect, useContext } from 'react'
import Filters from 'components/Filters/Filters'
import findAll from 'services/homeRallyService'
import { NotificationContext } from 'contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import LastSessions from './LastSessions/LastSessions'
import News from './News/News'
import Style from './Homepage.module.scss'
import Retailers from './Retailers/Retailers'
import KeyFigures from './KeyFigures/KeyFigures'

const Homepage = () => {
  const [startingPeriodRange, setStartingPeriodRange] = useState(undefined)
  const [finishPeriodRange, setFinishPeriodRange] = useState(undefined)
  const [rally, setRally] = useState({})
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation('error')

  const resetPeriodRange = () => {
    setStartingPeriodRange(undefined)
    setFinishPeriodRange(undefined)
  }

  useEffect(() => {
    async function getRally() {
      try {
        const token = localStorage.getItem('access_token')
        const { data } = await findAll(
          token,
          startingPeriodRange,
          finishPeriodRange
        )
        setRally(data)
      } catch (error) {
        openSnackBar(
          `${t('homepage:notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }
    getRally()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingPeriodRange, finishPeriodRange])

  return (
    <>
      <Filters
        startingPeriodRange={startingPeriodRange}
        finishPeriodRange={finishPeriodRange}
        changeStartingPeriodRange={(value) => setStartingPeriodRange(value)}
        changeFinishPeriodRange={(value) => setFinishPeriodRange(value)}
        resetPeriodRange={resetPeriodRange}
        context="homepage"
      />
      <div className={`content ${Style.homepage}`}>
        <KeyFigures
          total_kilometers={rally.total_kilometers}
          front_optimal_range={rally.front_optimal_range}
          rear_optimal_range={rally.rear_optimal_range}
          session_count={rally.session_count}
        />
        <News />
        <LastSessions sessions={rally.last_sessions} />
        <Retailers />
        <br />
      </div>
    </>
  )
}

export default Homepage
