import React from 'react'
import NewsListItem from './NewsListItem/NewsListItem'

const NewsList = ({ news }) => {
  return (
    <ul>
      {news.map((n) => (
        <NewsListItem item={n} key={n.id} />
      ))}
    </ul>
  )
}

export default NewsList
