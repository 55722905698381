import React from 'react'
import { useTranslation } from 'react-i18next'

import Flex from 'components/Flex'
import { ReactComponent as IconHelp } from 'img/icon_help.svg'
import { ReactComponent as IconNextArrow } from 'img/icon_arrow_next.svg'
import { ReactComponent as IconMail } from 'img/icon_mail.svg'
import backgroundMichelin from 'img/img_michelin_help.svg'

import Style from './Help.module.scss'

const Help = () => {
  const { t, i18n } = useTranslation(['help', 'link'])
  return (
    <div
      style={{
        background: `url(${backgroundMichelin}) no-repeat`,
        backgroundPosition: 'right bottom',
        backgroundSize: 'contain',
        height: `calc(100vh - 100px)`,
        overflow: 'hidden',
      }}
    >
      <div className={Style.help}>
        <Flex container flexDirection="column">
          <section>
            <div className={Style.title}>{t('help:section1_title')}</div>
            <p>
              {t('help:section1_p')}
              <br />
              {t('help:section1_p_end')}
            </p>
          </section>
          <section>
            <div className={Style.title}>{t('help:section2_title')}</div>
            <p>{t('help:section2_p')}</p>
            <a
              className={Style.downloadPdf}
              href={`${process.env.REACT_APP_URL}/manual/${i18n.languages[1]}/manual.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconHelp className={Style.iconHelp} />
              <b>{t('link:user_manual')}</b>
              <IconNextArrow className={Style.iconArrow} />
            </a>
          </section>
          <section>
            <div className={Style.title}>{t('help:section3_title')}</div>
            <p>{t('help:section3_p')}</p>
            <a
              className={Style.downloadPdf}
              href={`${process.env.REACT_APP_URL}/faq/${i18n.languages[1]}/faq.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconHelp className={Style.iconHelp} />
              <b>{t('link:faq')}</b>
              <IconNextArrow className={Style.iconArrow} />
            </a>
          </section>
        </Flex>
        <Flex container flexDirection="column">
          <section>
            <div className={Style.title}>{t('help:section4_title')}</div>
            <p>
              {t('help:section4_p')}
              <br />
              {t('help:section4_p_end')} :
            </p>
            <a
              href="mailto:compte-fonction.michelintrackconnect-msp@michelin.com"
              className={Style.contact}
            >
              <IconMail />
              {t('link:contact_us')}
            </a>
          </section>
        </Flex>
      </div>
    </div>
  )
}

export default Help
