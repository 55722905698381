export const GREEN_COLOR = '#6BBD4E'
export const ORANGE_COLOR = '#FA9928'
export const RED_COLOR = '#ff3737'

export const PRIMARY_COLOR = '#27509B'
export const ARG_COLOR = '#A6B7D6'
export const YELLOW_COLOR = '#FCE500'
export const ARD_COLOR = '#E91E63'

export const RED_LIGHT_COLOR = '#FFE1E1'
export const ORANGE_LIGHT_COLOR = '#FEF0DF'
export const GREEN_LIGHT_COLOR = '#DFF1D9'
export const BLUE_LIGHT_COLOR = '#A9D5EB'

export const GREY_LIGHT_COLOR = '#F4F7FB'

export const GREY_COLOR = '#858585'
export const GREY_DARK_COLOR = '#212121'
