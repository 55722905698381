import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStateIfMounted } from 'use-state-if-mounted'
import Filters from 'components/Filters/Filters'
import OptimalRangeOperation from 'components/OptimalRangeOperation/OptimalRangeOperation'
import { NotificationContext } from 'contexts/NotificationContext'
import {
  getRallySession,
  getStageRunsForSession,
} from 'services/rallySessionService'
import { useTranslation } from 'react-i18next'
import Style from './RunsRally.module.scss'
import RunsRallyInfo from './RunsRallyInfos'
import RunsRallyMyVehicle from './RunsRallyMyVehicle'
import RunsRallyTips from './RunsRallyTips/RunsRallyTips'
import RunsRallyRecordedTime from './RunsRallyRecordedTime'
import RunsRallyPressureEvolution from './RunsRallyPressureEvolution'
import RunsRallyTimePressure from './RunsRallyRealTimePressure'

const RunsRally = ({ history }) => {
  const [session, setSession] = useStateIfMounted([])
  const [runCurrent, setRunCurrent] = useStateIfMounted(undefined)
  const { idSession, idRun } = useParams()
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation('run')

  useEffect(() => {
    async function getSessionRally() {
      try {
        const token = localStorage.getItem('access_token')

        const { data } = await getRallySession(token, idSession)

        const run = data.rally_runs.find((r) => r.id === Number(idRun))
        setSession(data)
        setRunCurrent(run)
      } catch (error) {
        openSnackBar(
          `${t('run:notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }
    getSessionRally()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSession, idRun])

  const rallyRuns = getStageRunsForSession(session)

  function onChangeRun(id) {
    const newRun = rallyRuns.find((run) => run.id === id)
    setRunCurrent(newRun)
  }

  return (
    <>
      {session.length !== 0 && runCurrent && (
        <>
          <Filters
            goBack={history.goBack}
            noCalendar
            items={rallyRuns.sort((a, b) => a.start_date - b.start_date)}
            idCurrent={runCurrent.id}
            onChange={onChangeRun}
          />
          <div className={Style.parent}>
            <RunsRallyInfo
              run={runCurrent}
              name={session.name}
              kilometers={session.distance}
              sessionId={session.id}
            />
            <RunsRallyTimePressure
              tirePressures={runCurrent.rally_tire_pressures}
              isVisible={runCurrent.rally_car_locations.length !== 0}
              tireBoundaries={runCurrent.rally_dimension.rally_boundaries.filter(
                (boundarie) =>
                  boundarie.weather_type === runCurrent.weather_type
              )}
            />
            <RunsRallyRecordedTime stage={runCurrent} />
            <RunsRallyPressureEvolution
              isVisible={runCurrent.rally_car_locations.length !== 0}
              tirePressures={runCurrent.rally_tire_pressures}
            />
            <div className={Style.OptimalParent}>
              <OptimalRangeOperation
                front_optimal_range={runCurrent.front_optimal_range}
                rear_optimal_range={runCurrent.rear_optimal_range}
                showImg
              />
            </div>
            <RunsRallyMyVehicle run={runCurrent} />
            <RunsRallyTips
              sessionId={session.id}
              isVisible={runCurrent.rally_car_locations.length !== 0}
              tirePressures={runCurrent.rally_tire_pressures}
            />
            <br />
          </div>
        </>
      )}
    </>
  )
}

export default RunsRally
