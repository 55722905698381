import React from 'react'

import { ReactComponent as IconPrevArrow } from 'img/icon_back_arrow.svg'

function PrevArrow({ className, onClick, customStyle }) {
  return (
    <IconPrevArrow
      className={className}
      onClick={onClick}
      style={{ ...customStyle, display: 'block' }}
    />
  )
}

export default PrevArrow
