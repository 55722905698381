import React, { useState } from 'react'
import ClassNames from 'classnames'
import { ReactComponent as IconSearch } from 'img/icon_search.svg'
import data from 'data/retailers.json'
import { useTranslation } from 'react-i18next'
import Style from './RetailersAutocomplete.module.scss'

function getRetailer(geojson) {
  return geojson.features.map((feature) => feature)
}

const RetailersAutocomplete = ({ handleClickRetailer }) => {
  const retailers = getRetailer(data)
  const [value, setValue] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [filteredRetailers, setFilteredRetailers] = useState(retailers)
  const { t } = useTranslation('homepage')

  const handleSearch = (event) => {
    setFilteredRetailers(
      retailers.filter(
        (retailer) =>
          retailer.properties.name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1
      )
    )
    if (event.target.value !== '') {
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
    setValue(event.target.value)
  }

  const handleClick = (coordinates) => {
    handleClickRetailer(coordinates)
    setShowOptions(false)
  }

  return (
    <div
      className={ClassNames(Style.autocomplete, {
        [Style.open]: showOptions,
      })}
    >
      <div className={Style.searchBar}>
        <IconSearch className={Style.iconSearch} />
        <input
          type="text"
          onChange={handleSearch}
          value={value}
          placeholder={t('homepage:retailers.searchBar.placeholder')}
        />
      </div>
      {showOptions && (
        <div className={Style.options}>
          {filteredRetailers.map((retailer) => {
            return (
              <div
                onClick={() => handleClick(retailer.geometry.coordinates)}
                onKeyPress={() => handleClick(retailer.geometry.coordinates)}
                role="button"
                key={retailer.properties.name}
              >
                {retailer.properties.name}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RetailersAutocomplete
