import axios from 'axios'
import queryString from 'query-string'
import { getI18n } from 'react-i18next'

const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const query = (type, limit, page) => {
  return queryString.stringify(
    {
      type,
      limit,
      page,
    },
    {
      skipNull: true,
    }
  )
}

const getCatalogsItems = (token, type, limit, page) =>
  axios.get(`${apiPortalUrl}/catalogs-items?${query(type, limit, page)}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

const getCatalogsTags = (token) =>
  axios.get(`${apiPortalUrl}/catalogs-tags`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

export { getCatalogsItems, getCatalogsTags }
