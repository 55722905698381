import React from 'react'
import { PRIMARY_COLOR } from 'constants/colors'
import PlayerYoutube from '../../../components/PlayerYoutube'
import TireEssentialDetailList from './TireEssentialDetailList/TireEssentialDetailList'
import Style from './TireEssentialDetail.module.scss'

const TireEssentialDetail = ({
  tireEssential,
  tagsName,
  tireEssentials,
  handleClick,
  tags,
}) => {
  return (
    <>
      <h1>
        {tireEssential.name}{' '}
        {tagsName.map((tag) => {
          return (
            <span key={tag} style={{ color: PRIMARY_COLOR }}>
              #{tag}{' '}
            </span>
          )
        })}
      </h1>
      <div className={Style.tireEssentialDetail}>
        <PlayerYoutube videoId={tireEssential.link} />
        <TireEssentialDetailList
          items={tireEssentials.filter((tire) => tire.id !== tireEssential.id)}
          handleClick={handleClick}
          tags={tags}
        />
      </div>
    </>
  )
}

export default TireEssentialDetail
