import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import { updateUser, getUser } from 'services/userService'
import { NotificationContext } from 'contexts/NotificationContext'
import Style from './AccountInformation.module.scss'

const AccountInformation = () => {
  const { openSnackBar } = useContext(NotificationContext)
  const [values, setValues] = useState({
    firstName: {
      editable: false,
      value: '',
    },
    lastName: {
      editable: false,
      value: '',
    },
    email: {
      value: '',
    },
    nickname: {
      editable: false,
      value: '',
    },
  })
  const { t } = useTranslation(['account', 'button'])
  const token = localStorage.getItem('access_token')

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: { editable: true, value: event.target.value },
    })
  }

  const handleValidateForm = async () => {
    try {
      const body = {}

      Object.entries(values).forEach((entry) => {
        const [key, value] = entry
        body[key] = value.value
      })

      await updateUser(token, body)
      openSnackBar(t('account:notification.form_modified.success'), 'success')
    } catch (error) {
      openSnackBar(
        `${t('account:notification.form_modified.error')}: ${error.message}`,
        'error'
      )
    }
  }

  const handleKeyDown = (prop) => async (event) => {
    if (event.keyCode === 13) {
      setValues({
        ...values,
        [prop]: { value: values[prop].value, editable: false },
      })
    }
  }

  const handleChangeEdit = (prop) => {
    setValues({
      ...values,
      [prop]: { value: values[prop].value, editable: true },
    })
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await getUser(token)
        setValues((v) => ({
          ...v,
          lastName: { ...v.lastName, value: data.lastName },
          firstName: { ...v.firstName, value: data.firstName },
          nickname: { ...v.nickname, value: data.nickname },
          email: { ...v.email, value: data.email },
        }))
      } catch (error) {
        openSnackBar(
          `${t('account:notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }

    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={Style.accountInformation}>
      <h2>{t('account:information.title')}</h2>
      <div className={Style.form}>
        <TextField
          type="text"
          label={t('account:information.pseudo')}
          value={values.nickname.value}
          editable={values.nickname.editable}
          handleChange={() => handleChange('nickname')}
          handleKeyDown={() => handleKeyDown('nickname')}
          handleChangeEdit={() => handleChangeEdit('nickname')}
        />
        <TextField
          type="text"
          label={t('account:information.first_name')}
          value={values.firstName.value}
          editable={values.firstName.editable}
          handleChange={() => handleChange('firstName')}
          handleKeyDown={() => handleKeyDown('firstName')}
          handleChangeEdit={() => handleChangeEdit('firstName')}
        />
        <TextField
          type="text"
          label={t('account:information.last_name')}
          value={values.lastName.value}
          editable={values.lastName.editable}
          handleChange={() => handleChange('lastName')}
          handleKeyDown={() => handleKeyDown('lastName')}
          handleChangeEdit={() => handleChangeEdit('lastName')}
        />
        <TextField type="email" label="email" value={values.email.value} />
      </div>
      <button type="button" onClick={handleValidateForm}>
        {t('button:validate')}
      </button>
    </div>
  )
}

export default AccountInformation
