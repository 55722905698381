import auth0 from 'auth0-js'
import { AUTH0_ID_TOKEN_PAYLOAD, AUTH_CONFIG } from 'constants/auth0'

export default class Auth {
  constructor() {
    this.isAuth = null
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientId,
      redirectUri: window.location.origin.toString() + AUTH_CONFIG.callbackUrl,
      audience: AUTH_CONFIG.audience,
      responseType: 'token id_token',
      scope: 'openid profile',
    })

    if (!Auth.instance) {
      this.login = this.login.bind(this)
      this.handleAuthentication = this.handleAuthentication.bind(this)
      Auth.instance = this
    }
    return Auth.instance
  }

  // Set properties in localStorage
  static setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresInSecond = authResult.expiresIn * 1000
    const expiresAt = JSON.stringify(expiresInSecond + new Date().getTime())
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    localStorage.setItem('nickname', authResult.idTokenPayload.nickname)
    localStorage.setItem('email', authResult.idTokenPayload.name)
    localStorage.setItem('picture', authResult.idTokenPayload.picture)
    localStorage.setItem(
      'role',
      authResult.idTokenPayload[AUTH0_ID_TOKEN_PAYLOAD]
    )
    localStorage.setItem('in_db', 'inDB')
    return true
  }

  static async logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('role')
    localStorage.removeItem('nickname')
    localStorage.removeItem('email')
    localStorage.removeItem('in_db')
    return true
  }

  // Check localStorage to get token information
  static isAuthenticated() {
    // todo make this function async when you want to get new token with refresh token
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    const isInDatabase = localStorage.getItem('in_db') === 'inDB'
    // TODO Refresh token
    return new Date().getTime() < expiresAt && isInDatabase
  }

  login() {
    this.auth0.authorize()
  }

  // Set isAuth after auth0 parseHash
  authAfterParse = (err, authResult) => {
    if (err) {
      throw err
    }
    if (authResult && authResult.accessToken && authResult.idToken) {
      this.isAuth = Auth.setSession(authResult)
    } else {
      this.isAuth = false
    }
  }

  // sleep method, only use when waiting auth0.parseHash
  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  // use auth0 to parse auth information
  handleAuthentication = async (recursive = false) => {
    if (!recursive) {
      await this.auth0.parseHash(this.authAfterParse)
    }
    // recursive to wait the end of auth0.parseHash
    if (this.isAuth !== null) {
      return this.isAuth
    }
    await this.timeout(100)
    return this.handleAuthentication(true)
  }
}
