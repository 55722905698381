import React from 'react'
import { useTranslation } from 'react-i18next'

const Chrono = (value) => {
  const { t } = useTranslation('table')
  return (
    <>
      <b>{t('table:cell_chrono')} :</b>{' '}
      {`0${value.row.original.chrono}`.slice(-2)}
    </>
  )
}

export default Chrono
