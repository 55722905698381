import React from 'react'

const Select = ({ handleChange, value, options, className }) => {
  return (
    <select onChange={handleChange} value={value} className={className}>
      {options.map((option) => {
        return (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        )
      })}
    </select>
  )
}

export default Select
