import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/translation.json'
import translationFR from './locales/fr/translation.json'
import translationDE from './locales/de/translation.json'
import translationUS from './locales/us/translation.json'
import translationZH from './locales/zh/translation.json'
import translationNL from './locales/nl/translation.json'
import translationAT from './locales/at/translation.json'
import translationIT from './locales/it/translation.json'
import translationES from './locales/es/translation.json'

const resources = {
  'en-GB': translationEN,
  fr: translationFR,
  'de-DE': translationDE,
  'en-US': translationUS,
  zh: translationZH,
  nl: translationNL,
  'de-AT': translationAT,
  it: translationIT,
  es: translationES,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: [
        'navigator', // first take browser language
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    fallbackLng: (code) => {
      if (!code || code === 'en') {
        return ['en-GB']
      }
      if (code === 'de') {
        return ['de-DE']
      }
      const fallbacks = [code]
      if (code.startsWith('en-') && !['en-US', 'en-GB'].includes(code)) {
        fallbacks.push('en-GB')
      }
      if (code.startsWith('de-') && !['de-DE', 'de-AT'].includes(code)) {
        fallbacks.push('de-DE')
      }
      // add pure lang (it-IT => it)
      const langPart = code.split('-')[0]
      if (langPart !== code) {
        fallbacks.push(langPart)
      }
      // default
      fallbacks.push('en-GB')
      return fallbacks
    },
    debug: true,
    ns: [
      'account',
      'button',
      'catalog',
      'error',
      'help',
      'homepage',
      'menu',
      'login',
      'run',
      'runs',
      'tire_essential',
      'vehicle',
      'vehicles',
    ],

    /* interpolation: {
      escapeValue: false,
    }, */
  })

export default i18n
