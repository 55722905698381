import axios from 'axios'
import { getI18n } from 'react-i18next'

const apiUrl = process.env.REACT_APP_API_URL
const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const getUser = (token) =>
  axios.get(`${apiPortalUrl}/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

const updateUser = (token, body) =>
  axios.put(`${apiUrl}/users`, body, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

export { getUser, updateUser }
