import React from 'react'
import PropTypes, { shape } from 'prop-types'

import Flex from 'components/Flex'
import Session from 'views/homepage/Session/Session'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import NextArrow from 'components/NextArrow'
import Style from './LastSessions.module.scss'

const NoSession = () => {
  const { t } = useTranslation(['homepage', 'common', 'button'])
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      container
      flexDirection="column"
      height="70%"
    >
      <div className={Style.noDataTitle}>{t('common:no_data')}</div>
      <div className={Style.noDataText}>
        {t('common:no_data_description')}
        <br />
        {t('common:no_data_description_end')}
      </div>
      <button type="button" className={Style.button_refresh}>
        {t('button:refresh')}
      </button>
    </Flex>
  )
}

const LastSessions = ({ sessions }) => {
  const { t } = useTranslation(['homepage', 'button'])
  const history = useHistory()

  const handleClickSessions = () => {
    history.push('/runs')
  }
  return (
    <div className={Style.card}>
      <Flex container justifyContent="space-between" alignItems="center">
        <h2>{t('homepage:last_session.title')}</h2>

        <button
          onClick={handleClickSessions}
          type="button"
          className={Style.button_sessions}
        >
          {t('button:view_all_sessions')}
          <NextArrow
            customStyle={{ marginLeft: '0.5rem', display: 'inline' }}
          />
        </button>
      </Flex>

      {sessions && sessions.length !== 0 ? (
        sessions.map((session) => {
          return (
            <Flex container flexDirection="column" key={session.id}>
              <Session item={session} />
            </Flex>
          )
        })
      ) : (
        <NoSession />
      )}
    </div>
  )
}

LastSessions.defaultProps = {
  sessions: [],
}

LastSessions.propTypes = {
  sessions: PropTypes.arrayOf(
    shape({
      id: PropTypes.number,
      date: PropTypes.number,
      rally_runs: PropTypes.arrayOf(
        shape({
          id: PropTypes.number,
        })
      ),
    })
  ),
}

export default LastSessions
