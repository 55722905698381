import { ORANGE_COLOR, GREEN_COLOR, RED_COLOR } from 'constants/colors'

/**
 *
 * @param isFront boolean
 * @param value string
 *
 * @returns string
 */
const getColorPressure = (isFront, value, tireBoundaries) => {
  const tireBoundariesFront = tireBoundaries.find((t) => t.is_front)
  const tireBoundariesRear = tireBoundaries.find((t) => !t.is_front)

  if (!value) {
    return ''
  }
  if (isFront) {
    if (
      value >= tireBoundariesFront.orange_red ||
      value <= tireBoundariesFront.red_orange
    ) {
      return RED_COLOR
    }
    if (
      value >= tireBoundariesFront.orange_green &&
      value <= tireBoundariesFront.green_orange
    ) {
      return GREEN_COLOR
    }
    return ORANGE_COLOR
  }
  if (
    value >= tireBoundariesRear.orange_red ||
    value <= tireBoundariesRear.red_orange
  ) {
    return RED_COLOR
  }
  if (
    value >= tireBoundariesRear.orange_green &&
    value <= tireBoundariesRear.green_orange
  ) {
    return GREEN_COLOR
  }
  return ORANGE_COLOR
}

export default getColorPressure
