import React from 'react'
import PropTypes from 'prop-types'

import LogoMichelin from 'img/logo_michelin_horizontal.svg'

import Flex from 'components/Flex'
import Dropdown from 'components/Dropdown/Dropdown'
import DropdownItem from 'components/Dropdown/DropdownItem/DropdownItem'
import PreferencesPanel from 'components/Layout/PreferencesPanel/PreferencesPanel'

import { useTranslation } from 'react-i18next'
import Style from './Header.module.scss'

const Header = ({ logout }) => {
  const { t } = useTranslation('dropdown')
  return (
    <Flex
      container
      alignItems="center"
      height="auto"
      flexDirection="row"
      className={Style.header}
    >
      <img src={LogoMichelin} alt="logo" className={Style.logo} />
      <Flex container alignItems="center" justifyContent="flex-end">
        <PreferencesPanel />
        <span>{localStorage.getItem('nickname')}</span>
        <Dropdown url={localStorage.getItem('picture')} size={55}>
          <DropdownItem label={t('dropdown:account')} link="/account" isLink />
          <DropdownItem
            handleClick={logout}
            label={t('dropdown:logout')}
            style={{ fontWeight: 'bold' }}
          />
        </Dropdown>
      </Flex>
    </Flex>
  )
}

Header.defaultProps = {
  logout: () => {},
}

Header.propTypes = {
  logout: PropTypes.func,
}

export default Header
