import React, { useContext } from 'react'
import { format, fromUnixTime } from 'date-fns'
import Slider from 'react-slick'
import ClassNames from 'classnames'
import NextArrow from 'components/NextArrow'
import PrevArrow from 'components/PrevArrow'
import { TimeContext } from 'contexts/TimeContext'
import { useTranslation } from 'react-i18next'
import Style from './Stages.module.scss'

const Stages = ({ runs, idRunCurrent, onChange }) => {
  const { setIsReset, setPlay } = useContext(TimeContext)
  const { t } = useTranslation('common')

  const handleChange = (id) => {
    setIsReset(true)
    setPlay(false)
    onChange(id)
  }

  return (
    <Slider
      dots={false}
      slidesToShow={4}
      infinite={false}
      focusOnSelect={false}
      slidesToScroll={4}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      className={Style.sliderStages}
    >
      {runs.map((stage, index) => {
        const dateFormated = format(fromUnixTime(stage.start_date), 'H:mm')
        return (
          <div
            className={ClassNames(Style.stage, {
              [Style.active]: stage.id === idRunCurrent,
            })}
            key={stage.id}
            onClick={() => handleChange(stage.id)}
            onKeyPress={() => handleChange(stage.id)}
            role="button"
          >
            <div className={Style.slide}>
              <div>
                {t('common:stage.title')} {index + 1}
              </div>{' '}
              <div>{dateFormated.replace(/:/, 'h')}</div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default Stages
