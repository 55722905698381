import React, { useContext, useState } from 'react'
import { NotificationContext } from 'contexts/NotificationContext'
import { sendAnalysisRequest } from 'services/rallySessionService'
import { useTranslation } from 'react-i18next'
import Style from './RunsRallyTipsModal.module.scss'

const RunsRallyTipsModal = ({ sessionId }) => {
  const [text, setText] = useState('')
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation(['run', 'button'])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const token = localStorage.getItem('access_token')

    try {
      await sendAnalysisRequest(token, sessionId, text)
      openSnackBar(t('run:tips.notification.send_email.success'), 'success')
    } catch (error) {
      openSnackBar(
        `${t('run:tips.notification.send_email.error')}: ${error.message}`,
        'error'
      )
    }
  }
  return (
    <form onSubmit={handleSubmit} className={Style.form}>
      <h2>{t('run:tips.modal.title')}</h2>
      <p>{t('run:tips.modal.paragraph')}</p>
      <label>
        {t('run:tips.modal.label')} <br />
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={t('run:tips.modal.placeholder')}
        />
      </label>
      <input type="submit" value={t('button:send')} />
    </form>
  )
}

export default RunsRallyTipsModal
