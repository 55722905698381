import React from 'react'
import VignetteTireEssential from 'components/VignetteTireEssential/VignetteTireEssential'
import Style from './TireEssentialDetailList.module.scss'

const TireEssentialDetailList = ({ items, handleClick, tags }) => {
  return (
    <div className={Style.tireEssentialDetailList}>
      {items.map((item) => {
        return (
          <VignetteTireEssential
            width="100%"
            height="225px"
            tireEssential={item}
            key={item.id}
            tagsName={item.catalog_tags.map(
              (t) => tags.find((tag) => tag.id === t).name
            )}
            handleClick={handleClick}
          />
        )
      })}
    </div>
  )
}

export default TireEssentialDetailList
