import React, { useState } from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import Flex from 'components/Flex'
import { ReactComponent as ImgCamberAngle } from 'img/img_camber_angle.svg'
import { ReactComponent as IconEdit } from 'img/icon_edit.svg'
import { useTranslation } from 'react-i18next'
import Style from './CamberAngleItem.module.scss'

const CamberAngleItem = ({
  title,
  value,
  isEditable,
  editable,
  handleChange,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const { t } = useTranslation('common')

  const handleChangeValue = (event) => {
    setInputValue(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleChange(false, inputValue)
    }
  }

  return (
    <div
      className={ClassNames(Style.camberAngleItem, {
        [Style.editable]: editable,
      })}
    >
      <b className={Style.title}>{title}</b>
      <Flex container flexDirection="row" alignItems="center">
        <div>
          {t('common:camber_angle.left')}
          <br />
          <span className={Style.angle}>
            {isEditable ? (
              <input
                type="number"
                value={inputValue}
                onChange={handleChangeValue}
                onKeyDown={handleKeyDown}
                min={-3}
                max={1}
              />
            ) : (
              <>
                {value}
                <span className={Style.unit}>°</span>
              </>
            )}

            {editable && !isEditable && (
              <IconEdit onClick={() => handleChange(true, value)} />
            )}
          </span>
        </div>
        <ImgCamberAngle
          height={editable ? '100px' : '50px'}
          width={editable ? '200px' : '100px'}
          style={{ padding: '0 1rem' }}
        />
        <div>
          {t('common:camber_angle.right')}
          <br />
          <span className={Style.angle}>
            {isEditable ? (
              <input
                type="number"
                value={inputValue}
                onChange={handleChangeValue}
                onKeyDown={handleKeyDown}
                min={-3}
                max={1}
              />
            ) : (
              <>
                {value}
                <span className={Style.unit}>°</span>
              </>
            )}

            {editable && !isEditable && (
              <IconEdit onClick={() => handleChange(true, value)} />
            )}
          </span>
        </div>
      </Flex>
    </div>
  )
}

CamberAngleItem.defaultProps = {
  title: undefined,
  value: undefined,
  isEditable: false,
  editable: false,
  handleChange: () => {},
}

CamberAngleItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  isEditable: PropTypes.bool,
  editable: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default CamberAngleItem
