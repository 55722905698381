import React, { useEffect, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { format, fromUnixTime } from 'date-fns'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NextArrow } from 'img/icon_arrow_next.svg'
import findAll from 'services/runsService'
import { NotificationContext } from 'contexts/NotificationContext'
import Table from 'components/Table/Table'
import DistanceDisplay from 'components/DistanceDisplay'
import RallyDimension from 'components/Table/RallyDimension'
import Chrono from 'components/Table/Chrono'
import Style from './MySessions.module.scss'
import getDefaultNameRun from '../../../utils/getDefaultNameRun'

const limit = 8
let order = 'asc'
let orderby = 'date'

const MySessions = ({ startingPeriodRange, finishPeriodRange }) => {
  const [sessions, setSessions] = useStateIfMounted([])
  const { openSnackBar } = useContext(NotificationContext)
  const history = useHistory()
  const { t } = useTranslation(['table', 'runs'])
  const token = localStorage.getItem('access_token')

  const columns = useMemo(
    () => [
      {
        Header: t('table:column.name'),
        accessor: (row) => (row.name ? row.name : getDefaultNameRun(row.date)),
        disableSortBy: true,
      },
      {
        Header: t('table:column.date'),
        accessor: (row) => format(fromUnixTime(row.date), 'dd/MM/yyyy'),
        sortDescFirst: true,
      },
      {
        Header: t('table:column.vehicles'),
        accessor: (row) => `${row.rally_brand_name} - ${row.rally_model_name}`,
        disableSortBy: true,
      },
      {
        Header: t('table:column.tires_dimensions'),
        Cell: (value) => RallyDimension(value),
        disableSortBy: true,
      },
      {
        Header: t('table:column.recorded_time'),
        Cell: (value) => Chrono(value),
      },
      {
        Header: t('table:column.recorded_distance'),
        Cell: (value) => {
          const { distance } = value.row.original
          return <DistanceDisplay distanceValue={distance} />
        },
      },
      {
        accessor: () => '',
        id: '1',
        Cell: () => {
          return <NextArrow width={5} height={10} className={Style.nextArrow} />
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  async function getRallySessions() {
    try {
      const { data } = await findAll(token)
      setSessions(data)
    } catch (error) {
      openSnackBar(
        `${'runs:notification.get_data.error'}: ${error.message}`,
        'error'
      )
    }
  }

  useEffect(() => {
    getRallySessions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = useCallback(
    (sortByTable, pageIndex, startingPeriod, finishPeriod) => {
      async function getRallySessionsWithFilters() {
        try {
          if (sortByTable.length !== 0) {
            order = sortByTable[0].desc ? 'desc' : 'asc'
            orderby =
              sortByTable[0].id === `${t('table:cell.recorded_time')}`
                ? 'chrono'
                : sortByTable[0].id === `${t('table:cell.recorded_distance')}`
                ? 'distance'
                : sortByTable[0].id.toLowerCase()
          }

          const { data } = await findAll(
            token,
            startingPeriod,
            finishPeriod,
            pageIndex,
            limit,
            order,
            orderby
          )

          setSessions(data)
        } catch (error) {
          openSnackBar(
            `${'runs:notification.get_data.error'}: ${error.message}`,
            'error'
          )
        }
      }

      getRallySessionsWithFilters()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const goToRun = (values) => {
    history.push(
      `session/${values.original.id}/run/${values.original.rally_run_ids[0]}`
    )
  }

  if (sessions.length === 0) {
    return null
  }

  return (
    <div className={`${Style.runs} content`}>
      <h1>{t('runs:title')}</h1>
      <Table
        data={sessions}
        columns={columns}
        startingPeriodRange={startingPeriodRange}
        finishPeriodRange={finishPeriodRange}
        fetchData={fetchData}
        onEventClick={goToRun}
        goRefresh={() => getRallySessions()}
      />
      <br />
    </div>
  )
}

export default MySessions
