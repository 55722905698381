import React, { createContext, useState } from 'react'

const TimeContext = createContext({})

const TimeProvider = ({ children }) => {
  // time of the actual date of the stage (begin start date in secondes)
  const [time, setTime] = useState(undefined)

  // "fake time" actual date time (begin 00:00) and for car rally_car_locations in map
  const [timeSlider, setTimeSlider] = useState(0)

  // when click on Stage reset time
  const [isReset, setIsReset] = useState(false)

  // for media
  const [play, setPlay] = useState(false)
  const [previous, setPrevious] = useState(false)
  const [next, setNext] = useState(false)

  return (
    <TimeContext.Provider
      value={{
        time,
        setTime,
        isReset,
        setIsReset,
        timeSlider,
        setTimeSlider,
        play,
        setPlay,
        previous,
        setPrevious,
        next,
        setNext,
      }}
    >
      <>{children}</>
    </TimeContext.Provider>
  )
}

export { TimeContext, TimeProvider }
