import React from 'react'

const Flex = ({
  className,
  container,
  justifyContent,
  flexDirection,
  flexGrow,
  flexShrink,
  flexBasis,
  flexWrap,
  flex,
  alignItems,
  margin,
  padding,
  width,
  height,
  maxWidth,
  children,
}) => {
  return (
    <div
      className={className}
      style={{
        display: container ? 'flex' : 'block',
        justifyContent: justifyContent || 'flex-start',
        flexDirection: flexDirection || 'row',
        flexGrow: flexGrow || 0,
        flexBasis: flexBasis || 'auto',
        flexShrink: flexShrink || 1,
        flexWrap: flexWrap || 'nowrap',
        flex: flex || 'auto',
        alignItems: alignItems || 'stretch',
        margin: margin || '0',
        padding: padding || '0',
        width: width || 'auto',
        height: height || 'auto',
        maxWidth: maxWidth || 'none',
      }}
    >
      {children}
    </div>
  )
}

export default Flex
