import React, { useEffect, useContext } from 'react'
import Slider from 'react-slick'
import Flex from 'components/Flex'
import rallyCarService from 'services/rallyCarService'
import { NotificationContext } from 'contexts/NotificationContext'
import NextArrow from 'components/NextArrow'
import PrevArrow from 'components/PrevArrow'
import { useTranslation } from 'react-i18next'
import { useStateIfMounted } from 'use-state-if-mounted'
import Style from '../Vehicles.module.scss'
import VehiclesSliderItem from './VehiclesSliderItem/VehiclesSliderItem'

const responsive = [
  {
    breakpoint: 1900,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
]

const VehiclesSlider = () => {
  const [vehicles, setVehicles] = useStateIfMounted([])
  const { openSnackBar } = useContext(NotificationContext)
  const { t } = useTranslation('vehicles')

  useEffect(() => {
    async function getRallyCars() {
      try {
        const token = localStorage.getItem('access_token')

        const { data } = await rallyCarService.findAll(token)
        setVehicles(data)
      } catch (error) {
        openSnackBar(
          `${t('vehicles:notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }
    getRallyCars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (vehicles.length === 0) {
    return null
  }

  return (
    <Flex container flexDirection="row">
      <Slider
        dots={false}
        slidesToShow={3}
        infinite={false}
        slidesToScroll={3}
        nextArrow={<NextArrow customStyle={{ width: '12px' }} />}
        prevArrow={<PrevArrow customStyle={{ width: '12px' }} />}
        className={Style.customSlider}
        responsive={responsive}
      >
        {vehicles.map((vehicle) => {
          return (
            <div className={Style.card} key={vehicle.id}>
              <VehiclesSliderItem vehicle={vehicle} />
            </div>
          )
        })}
      </Slider>
    </Flex>
  )
}

export default VehiclesSlider
