import React, { createContext, useState } from 'react'
import {
  PREFERENCE_DISTANCE_DEFAULT,
  PREFERENCE_PRESSURE_DEFAULT,
  PREFERENCE_TEMPERATURE_DEFAULT,
} from '../constants/preferences'

const PreferencesContext = createContext({})

const PreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = useState({
    pressure: PREFERENCE_PRESSURE_DEFAULT,
    temperature: PREFERENCE_TEMPERATURE_DEFAULT,
    distance: PREFERENCE_DISTANCE_DEFAULT,
  })

  return (
    <PreferencesContext.Provider
      value={{
        preferences,
        setPreferences,
      }}
    >
      <>{children}</>
    </PreferencesContext.Provider>
  )
}

export { PreferencesContext, PreferencesProvider }
