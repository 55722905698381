import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import PropTypes, { shape } from 'prop-types'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import IconCalendar from 'img/icon_calendar.svg'
import { ReactComponent as PrevArrow } from 'img/icon_back_arrow.svg'

import Flex from 'components/Flex'

import Style from './Filters.module.scss'
import FiltersItems from './FiltersItems/FiltersItems'
import Autocomplete from './Autocomplete/Autocomplete'

const Filters = ({
  startingPeriodRange,
  finishPeriodRange,
  changeStartingPeriodRange,
  changeFinishPeriodRange,
  resetPeriodRange,
  context,
  noCalendar,
  goBack,
  items,
  idCurrent,
  onChange,
}) => {
  const [displayStartingCalendar, setDisplayStartingCalendar] = useState(false)
  const [displayFinishCalendar, setDisplayFinishCalendar] = useState(false)
  const { t } = useTranslation(['common', 'button'])

  const handleChangeFrom = (value) => {
    changeStartingPeriodRange(value)
    setDisplayStartingCalendar(false)
  }

  const handleChangeTo = (value) => {
    changeFinishPeriodRange(value)
    setDisplayFinishCalendar(false)
  }

  return (
    <Flex
      container
      flexDirection="row"
      padding="0 60px 0 60px"
      height="100px"
      alignItems="center"
      className={Style.container}
    >
      {context !== 'homepage' && (
        <span
          onClick={goBack}
          onKeyPress={goBack}
          className={Style.goBack}
          role="button"
        >
          <PrevArrow width="11px" height="22px" className={Style.arrowBack} />{' '}
          <span style={{ marginRight: '50px' }}>{t('common:back')}</span>
        </span>
      )}
      {context === 'runs' && (
        <span>
          <b style={{ marginRight: '24px' }}>
            {t('common:calendar.label_range')} :
          </b>{' '}
          {t('common:calendar.label_from_light')} :
        </span>
      )}
      {!noCalendar && (
        <>
          {context === 'homepage' && `${t('common:calendar.label_from')} :`}
          <span>
            <input
              type="text"
              onChange={handleChangeFrom}
              id="starting"
              name="starting"
              placeholder={t('common:calendar.input_start_placeholder')}
              value={
                startingPeriodRange
                  ? format(new Date(startingPeriodRange), 'dd.MM.yyyy')
                  : ''
              }
              onClick={setDisplayStartingCalendar}
              style={{
                background: `url(${IconCalendar}) no-repeat scroll 18px 11px`,
                paddingLeft: '50px',
                display: displayStartingCalendar ? 'none' : 'block',
              }}
            />
          </span>
          {displayStartingCalendar && (
            <Calendar
              onChange={handleChangeFrom}
              value={startingPeriodRange}
              className={Style.calendar}
              maxDate={finishPeriodRange}
            />
          )}
          {t('common:calendar.label_to')} :
          <input
            type="text"
            onChange={handleChangeTo}
            id="finish"
            name="finish"
            placeholder={t('common:calendar.input_end_placeholder')}
            value={
              finishPeriodRange
                ? format(new Date(finishPeriodRange), 'dd.MM.yyyy')
                : ''
            }
            onClick={setDisplayFinishCalendar}
            style={{
              background: `url(${IconCalendar}) no-repeat scroll 18px 11px`,
              paddingLeft: '50px',
              display: displayFinishCalendar ? 'none' : 'block',
            }}
          />
          {displayFinishCalendar && (
            <Calendar
              onChange={handleChangeTo}
              value={finishPeriodRange}
              className={Style.calendar}
              minDate={startingPeriodRange}
            />
          )}
          <button
            type="button"
            className={Style.buttonReset}
            onClick={resetPeriodRange}
          >
            {t('button:reset')}
          </button>
        </>
      )}
      {items && items.length !== 0 && (
        <FiltersItems
          items={items}
          context={context}
          idCurrent={idCurrent}
          onChange={onChange}
        />
      )}
      <Autocomplete />
    </Flex>
  )
}

Filters.defaultProps = {
  startingPeriodRange: undefined,
  finishPeriodRange: undefined,
  idCurrent: undefined,
  items: [],
  context: '',
  noCalendar: false,
  goBack: () => {},
  changeStartingPeriodRange: () => {},
  changeFinishPeriodRange: () => {},
  onChange: () => {},
  resetPeriodRange: () => {},
}

Filters.propTypes = {
  idCurrent: PropTypes.number,
  context: PropTypes.string,
  startingPeriodRange: PropTypes.instanceOf(Date),
  finishPeriodRange: PropTypes.instanceOf(Date),
  items: PropTypes.arrayOf(shape({})),
  changeStartingPeriodRange: PropTypes.func,
  changeFinishPeriodRange: PropTypes.func,
  resetPeriodRange: PropTypes.func,
  noCalendar: PropTypes.bool,
  goBack: PropTypes.func,
  onChange: PropTypes.func,
}

export default Filters
