import React from 'react'
import { PRIMARY_COLOR } from 'constants/colors'
import Style from './Pagination.module.scss'

const Pagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  pagesToPaginate,
  nextPage,
  canNextPage,
  pageIndex,
  pageCount,
}) => {
  if (pageCount === 1) {
    return null
  }
  return (
    <div className={Style.pagination}>
      <ul className={Style.paginationContent}>
        <li>
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={Style.pageLink}
          >
            {'<<'}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={Style.pageLink}
            style={{ borderLeft: 'none' }}
          >
            {'<'}
          </button>
        </li>
        <li>
          {pagesToPaginate.map((page) => {
            return (
              <span
                className={`${Style.pageLink} ${Style.pageLinkNumbers}`}
                key={page}
              >
                <button
                  key={page}
                  type="button"
                  style={{
                    color: page - 1 === pageIndex && PRIMARY_COLOR,
                    fontWeight: page - 1 === pageIndex && 'bold',
                    borderBottom:
                      page - 1 === pageIndex && `2px solid ${PRIMARY_COLOR}`,
                    cursor: 'pointer',
                  }}
                  onClick={() => gotoPage(page - 1)}
                >
                  {page}
                </button>
              </span>
            )
          })}
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={Style.pageLink}
            style={{ borderRight: 'none' }}
          >
            {'>'}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage || pageCount === pageIndex + 1}
            className={Style.pageLink}
          >
            {'>>'}
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
