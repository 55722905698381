function paginate(totalItems, currentPage = 1, pageSize = 8, maxPages = 3) {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize)
  let curPage = currentPage
  // ensure current page isn't out of range
  if (curPage < 1) {
    curPage = 1
  } else if (curPage > totalPages) {
    curPage = totalPages
  }

  let startPage
  let endPage
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1
    endPage = totalPages
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
    if (curPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1
      endPage = maxPages
    } else if (curPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1
      endPage = totalPages
    } else {
      // current page somewhere in the middle
      startPage = curPage - maxPagesBeforeCurrentPage
      endPage = curPage + maxPagesAfterCurrentPage
    }
  }

  // calculate start and end item indexes
  const startIndex = (curPage - 1) * pageSize
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

  // create an array of pages in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  )

  // return object with all pager properties required by the view
  return {
    totalItems,
    curPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  }
}

export default paginate
