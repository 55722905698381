import React, { useEffect, useContext, useState } from 'react'
import TirePressures from 'components/TirePressures/TirePressures'
import { ReactComponent as Vehicle } from 'img/vehicle01.svg'
import { TimeContext } from 'contexts/TimeContext'
import getSecondes from 'utils/getSecondes'
import { PRESSURE, TEMPERATURE } from 'constants/tire'
import getColorTemp from 'utils/getColorTemp'
import getColorPressure from 'utils/getColorPressure'
import Style from './RunsRallyRealTimePressureContent.module.scss'
import RunsRallyRealTimePressureVehicle from './RunsRallyRealTimePressureVehicle'

const RunsRallyRealTimePressureContent = ({
  time,
  tirePressures,
  checked,
  tireBoundaries,
}) => {
  const { isReset } = useContext(TimeContext)
  const tireEmpty = {
    temp: { value: '', color: '' },
    press: { value: '', color: '' },
  }
  const [tireFR, setTireFR] = useState(tireEmpty)
  const [tireFL, setTireFL] = useState(tireEmpty)
  const [tireRR, setTireRR] = useState(tireEmpty)
  const [tireRL, setTireRL] = useState(tireEmpty)

  const getElement = (isFront, elem) => {
    return {
      temp: {
        value: elem.temperature,
        color: getColorTemp(elem.temperature),
      },
      press: {
        value: elem.pressure,
        color: getColorPressure(isFront, elem.pressure, tireBoundaries),
      },
    }
  }

  useEffect(() => {
    tirePressures
      .sort((a, b) => a.trame_date - b.trame_date)
      .forEach((element) => {
        const dateInSecondes = getSecondes(element.trame_date)
        if (time === dateInSecondes) {
          setTireFR((tire) =>
            element.position === 0 ? getElement(true, element) : tire
          )
          setTireFL((tire) =>
            element.position === 1 ? getElement(true, element) : tire
          )
          setTireRR((tire) =>
            element.position === 2 ? getElement(false, element) : tire
          )
          setTireRL((tire) =>
            element.position === 3 ? getElement(false, element) : tire
          )
        }
      })
    if (isReset) {
      setTireFR(tireEmpty)
      setTireFL(tireEmpty)
      setTireRR(tireEmpty)
      setTireRL(tireEmpty)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, tirePressures, isReset])

  return (
    <>
      {time && (
        <RunsRallyRealTimePressureVehicle
          tireFR={tireFR}
          tireFL={tireFL}
          tireRR={tireRR}
          tireRL={tireRL}
          context={!checked ? PRESSURE : TEMPERATURE}
        />
      )}
      <div className={Style.contentPressure}>
        <Vehicle />
        {time && (
          <TirePressures
            tireFR={tireFR}
            tireFL={tireFL}
            tireRR={tireRR}
            tireRL={tireRL}
            context={!checked ? PRESSURE : TEMPERATURE}
          />
        )}
      </div>
    </>
  )
}

export default RunsRallyRealTimePressureContent
