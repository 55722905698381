import React, { useContext, useEffect } from 'react'
import { fromUnixTime, differenceInSeconds } from 'date-fns'

import Slider, { createSliderWithTooltip } from 'rc-slider'
import { TimeContext } from 'contexts/TimeContext'
import formatMinSec from 'utils/formatMinSec'
import getSecondes from 'utils/getSecondes'
import { PRIMARY_COLOR, BLUE_LIGHT_COLOR } from 'constants/colors'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const RunsRallyInfosSlider = ({ startDate, endDate, isVisible }) => {
  const diffInS = differenceInSeconds(
    fromUnixTime(endDate),
    fromUnixTime(startDate)
  )

  const minValue = getSecondes(startDate)
  const maxValue = diffInS + minValue
  const maxSlider = diffInS

  const {
    time,
    setTime,
    setIsReset,
    timeSlider,
    setTimeSlider,
    play,
    previous,
    next,
    setPrevious,
    setNext,
    setPlay,
    isReset,
  } = useContext(TimeContext)

  useEffect(() => {
    setTime(minValue)
    setTimeSlider(0)
    setIsReset(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxSlider, isReset])

  useEffect(() => {
    let interval = null
    if ((play && maxSlider !== timeSlider) || previous || next) {
      interval = setInterval(() => {
        if (previous) {
          setTime((secondes) => (secondes - 30 < 0 ? minValue : secondes - 30))
          setTimeSlider((tSlider) => (tSlider - 30 < 0 ? 0 : tSlider - 30))
          setPrevious(false)
        } else if (next) {
          setTime((secondes) =>
            secondes + 30 > maxValue ? maxValue : secondes + 30
          )
          setTimeSlider((tSlider) =>
            tSlider + 30 > maxSlider ? maxSlider : tSlider + 30
          )
          setNext(false)
        } else {
          setTime((secondes) => secondes + 1)
          setTimeSlider((tSlider) => tSlider + 1)
        }
      }, 1000)
    } else if (!play || maxSlider === timeSlider) {
      setPlay(false)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play, timeSlider, next, previous])

  const marks = {
    [minValue]: {
      style: {
        color: PRIMARY_COLOR,
      },
      label: '00:00',
    },
    [maxValue]: {
      style: {
        color: PRIMARY_COLOR,
      },
      label: formatMinSec(maxSlider),
    },
  }

  const onChange = (value) => {
    const realSeconds = value - minValue
    setTimeSlider(realSeconds)
    setTime(value)
  }

  const PropsTip = {
    placement: 'bottom',
    prefixCls: 'rc-slider-tooltip',
  }

  if (!isVisible || !time) {
    return null
  }

  return (
    <SliderWithTooltip
      style={{ marginTop: '1rem' }}
      className="customSlider"
      trackStyle={{ backgroundColor: PRIMARY_COLOR, height: 10 }}
      railStyle={{ backgroundColor: BLUE_LIGHT_COLOR, height: 10 }}
      handleStyle={{
        borderColor: 'white',
        height: 21,
        width: 21,
        backgroundColor: PRIMARY_COLOR,
      }}
      marks={marks}
      min={minValue}
      max={maxValue}
      value={!time ? minValue : time}
      onChange={onChange}
      tipFormatter={() => {
        return formatMinSec(timeSlider)
      }}
      tipProps={PropsTip}
    />
  )
}

export default RunsRallyInfosSlider
