import React, { useState, useContext } from 'react'
import ChoicePressOrTemp from 'components/ChoicePressOrTemp'
import Flex from 'components/Flex'
import ChartLines from 'components/ChartLines'
import { ReactComponent as Vehicle } from 'img/vehicle01.svg'
import { PRESSURE, TEMPERATURE } from 'constants/tire'
import { PreferencesContext } from 'contexts/PreferencesContext'
import { useTranslation } from 'react-i18next'
import Style from './RunsRallyPressureEvolution.module.scss'

const RunsRallyPressureEvolution = ({ tirePressures, isVisible }) => {
  const { preferences } = useContext(PreferencesContext)
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation(['common', 'run'])

  return (
    <div className={Style.card}>
      <Flex container justifyContent="space-between">
        <div>
          <h3>{t('run:pressure_evolution.title')}</h3>
        </div>
        <div>
          <ChoicePressOrTemp
            disabled={!isVisible}
            checked={checked}
            setChecked={(value) => setChecked(value)}
          />
        </div>
      </Flex>
      <Flex container justifyContent="flex-end" margin="1rem 0 0 0">
        <div className={Style.legend}>
          <Vehicle height={35} width={18} />
          <div className={`${Style.legendItem} ${Style.legendItemAVG}`}>
            {t('run:pressure_evolution.avg')}
          </div>
          <div className={`${Style.legendItem} ${Style.legendItemAVD}`}>
            {t('run:pressure_evolution.avd')}
          </div>
          <div className={`${Style.legendItem} ${Style.legendItemARG}`}>
            {t('run:pressure_evolution.arg')}
          </div>
          <div className={`${Style.legendItem} ${Style.legendItemARD}`}>
            {t('run:pressure_evolution.ard')}
          </div>
        </div>
      </Flex>
      {!isVisible ? (
        <Flex container justifyContent="center">
          <span className={Style.noData}>{t('common:no_data')}</span>
        </Flex>
      ) : (
        <ChartLines
          dataRun={tirePressures}
          context={!checked ? PRESSURE : TEMPERATURE}
          preferences={preferences}
        />
      )}
    </div>
  )
}

export default RunsRallyPressureEvolution
