import React from 'react'
import PropTypes from 'prop-types'
import Flex from 'components/Flex'
import CamberAngleItem from 'components/CamberAngleItem/CamberAngleItem'
import { useTranslation } from 'react-i18next'
import Style from './CamberAngle.module.scss'

const CamberAngle = ({
  front_camber,
  rear_camber,
  handleChangeRear,
  handleChangeFront,
}) => {
  const { t } = useTranslation(['common', 'vehicle'])
  return (
    <Flex
      container
      flexDirection="column"
      flex="1 1 0"
      margin="0 40px 0 0"
      alignItems="center"
      className={Style.card}
    >
      <h3>{t('vehicle:camber_angle_title')} :</h3>
      <Flex
        container
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
      >
        <CamberAngleItem
          title={t('common:tire.front')}
          value={front_camber.front}
          isEditable={front_camber.isEditable}
          editable
          handleChange={handleChangeFront}
        />
        <CamberAngleItem
          title={t('common:tire.rear')}
          value={rear_camber.rear}
          isEditable={rear_camber.isEditable}
          editable
          handleChange={handleChangeRear}
        />
      </Flex>
    </Flex>
  )
}

CamberAngle.defaultProps = {
  front_camber: {},
  rear_camber: {},
  handleChangeRear: () => {},
  handleChangeFront: () => {},
}

CamberAngle.propTypes = {
  front_camber: PropTypes.shape({
    front: PropTypes.number,
  }),
  rear_camber: PropTypes.shape({
    rear: PropTypes.number,
  }),
  handleChangeRear: PropTypes.func,
  handleChangeFront: PropTypes.func,
}

export default CamberAngle
