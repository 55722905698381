import React, { useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import Layout from 'components/Layout/Layout'
import {
  ROUTE_BASE,
  ROUTE_CALLBACK,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ACCOUNT,
} from 'constants/routes'
import { TimeProvider } from 'contexts/TimeContext'
import {
  Homepage,
  Login,
  Runs,
  VehicleDetails,
  Vehicles,
  RunsRally,
  Help,
  Account,
  Catalog,
  TireEssential,
} from './views'
import Auth from './services/Auth'

const auth = new Auth()

const App = () => {
  const history = useHistory()
  const location = useLocation()

  // if page load is in progress
  const [loading, setLoading] = useState(true)
  // if authentication is in progress
  const [waitingAuth, setWaitingAuth] = useState(false)
  // auth state (null / true / false)
  const [authenticated, setAuthenticated] = useState(null)
  // auth error
  const [error, setError] = useState(null)

  const logout = async () => {
    Auth.logout()
      .then(() => {
        setAuthenticated(false)
        setLoading(false)
      })
      .then(() => {
        history.replace(ROUTE_LOGIN)
      })
  }

  // Auth done or error, redirect user to the right route
  const stopWaiting = async () => {
    setLoading(false)
    setError(null)
    setWaitingAuth(false)
    history.replace(ROUTE_HOME)
  }

  // authentication

  // set auth state if not already set
  const setAuthentication = () => {
    if (authenticated === null) {
      const isAuth = Auth.isAuthenticated()
      setAuthenticated(isAuth)
      return isAuth
    }
    return authenticated
  }

  const handleAuthentication = async (actualLocation) => {
    if (/access_token|id_token|error/.test(actualLocation.hash)) {
      await auth.handleAuthentication()
      setAuthentication()
      stopWaiting()
    } else {
      if (authenticated === null) {
        setAuthentication()
      }
      stopWaiting()
    }
  }

  if (location.pathname === ROUTE_CALLBACK && authenticated == null) {
    if (!waitingAuth) {
      handleAuthentication(location)
      setWaitingAuth(true)
    }
  } else if (!waitingAuth) {
    // set auth for all pages except callback
    if (
      location.pathname !== ROUTE_CALLBACK &&
      authenticated === null &&
      error === null
    ) {
      setAuthentication()
      stopWaiting()
    }
  }

  if (error) {
    // TODO Change error display
    return <p>Error : {JSON.stringify(error)}</p>
  }

  if (loading || waitingAuth || authenticated === null) {
    // TODO Change loading display
    return <p>Loading</p>
  }

  if (!loading && !waitingAuth && authenticated === true) {
    return (
      <Switch>
        <Layout logout={logout}>
          <Route
            path={ROUTE_CALLBACK}
            key={ROUTE_CALLBACK}
            render={() => <p>Loading</p>}
          />
          <Route exact path="/runs" key="/runs" component={Runs} />
          <Route exact path="/vehicles" key="/vehicles" component={Vehicles} />
          <TimeProvider>
            <Route
              exact
              path="/session/:idSession/run/:idRun"
              key="/session/:idSession/run/:idRu"
              component={RunsRally}
            />
          </TimeProvider>
          <Route path={ROUTE_HOME} key={ROUTE_HOME} component={Homepage} />
          <Route
            exact
            path={ROUTE_ACCOUNT}
            key={ROUTE_ACCOUNT}
            component={Account}
          />
          <Route
            path="/vehicles/:id"
            key="/vehicles/:id"
            component={VehicleDetails}
          />
          <Route exact path="/catalogs" key="/catalog" component={Catalog} />
          <Route path="/catalogs/:id" key="/catalogs/:id" component={Catalog} />
          <Route
            exact
            path="/tire-essentials/:id"
            key="/tire-essentials/:id"
            component={TireEssential}
          />
          <Route
            exact
            path="/tire-essentials"
            key="/tire-essentials"
            component={TireEssential}
          />
          <Route path="/help" key="/help" component={Help} />
          {/* <Route
            exact
            path={ROUTE_LOGIN}
            key="/login-redirect"
            render={() => <Redirect to={ROUTE_HOME} />}
          />
          <Route
            exact
            path={ROUTE_BASE}
            key="/home-redirect"
            render={() => <Redirect to={ROUTE_HOME} />}
          /> */}
        </Layout>
      </Switch>
    )
  }

  if (!loading && !waitingAuth && authenticated === false) {
    return (
      <Switch>
        <Route
          path={ROUTE_CALLBACK}
          key={ROUTE_CALLBACK}
          render={() => <p>Loading</p>}
        />
        <Route path={ROUTE_LOGIN} key={ROUTE_LOGIN} component={Login} />
        <Route
          path={ROUTE_BASE}
          key="/login-redirect"
          render={() => <Redirect to={ROUTE_LOGIN} />}
        />
      </Switch>
    )
  }

  return null
}

export default App
