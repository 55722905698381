import React from 'react'
import { fromUnixTime, differenceInSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconChrono } from 'img/icon_chrono.svg'
import Flex from 'components/Flex'
import formatMinSec from 'utils/formatMinSec'
import Style from './RunsRallyRecordedTime.module.scss'

const RunsRallyRecordedTime = ({ stage }) => {
  const { t } = useTranslation('run')
  const diffInS = differenceInSeconds(
    fromUnixTime(stage.end_date),
    fromUnixTime(stage.start_date)
  )
  return (
    <Flex
      container
      className={Style.card}
      justifyContent="space-between"
      padding="22px"
    >
      <div>
        <h3>{t('run:recorded_time_title')}</h3>
        <Flex
          container
          className={Style.bestChrono}
          alignItems="center"
          padding="20 0 0 0"
        >
          <IconChrono width="32px" height="36px" />
          <i>{formatMinSec(diffInS)}</i>
        </Flex>
      </div>
    </Flex>
  )
}

export default RunsRallyRecordedTime
