import React from 'react'

import { ReactComponent as Vehicle } from 'img/vehicle01.svg'
import { useTranslation } from 'react-i18next'
import PressureDisplay from 'components/PressureDisplay'
import Style from './RunsRallyMyVehicle.module.scss'
import RunsRallyMyVehicleCamberAngle from './RunsRallyMyVehicleCamberAngle'

const RunsRallyMyVehicle = ({ run }) => {
  const { t } = useTranslation(['common', 'run'])
  return (
    <div className={Style.card}>
      <div className={Style.contentTop}>
        <div>
          <h3>{t('run:vehicle.title')}</h3>
          <div className={Style.brand}>
            {run.rally_dimension.rally_group.rally_model.rally_brand.name}
          </div>
          <div className={Style.model}>
            {run.rally_dimension.rally_group.rally_model.name}
          </div>
          <b className={Style.tiresTitle}>
            {t('run:vehicle.tires_dimensions')} :
          </b>
          <div className={Style.tire}>
            <b>{t('common:tire.av')}</b> : {run.rally_dimension.front_dimension}
          </div>
          <div className={Style.tire}>
            <b>{t('common:tire.ar')}</b> : {run.rally_dimension.rear_dimension}
          </div>
        </div>
        <RunsRallyMyVehicleCamberAngle
          front_camber={run.front_camber}
          rear_camber={run.rear_camber}
        />
      </div>
      <div className={Style.contentTire}>
        <Vehicle />
        <div className={Style.tire}>
          <b className={Style.tireTitle}>{t('common:pressure.tire_fl')}</b>
          <div style={{ paddingTop: '8px' }}>
            {t('run:vehicle.gum_hardness')} :
          </div>
          <b className={Style.numberGum}>
            {run.front_left_rally_gum_notch
              ? run.front_left_rally_gum_notch.notch
              : '-'}
          </b>
          <b style={{ display: 'block', paddingTop: '10px' }}>
            {t('run:vehicle.pressure_recommendation')} :
          </b>
          <div className={Style.contentPressure} style={{ paddingTop: '10px' }}>
            {t('common:pressure.cold')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.front_min_cold_pressure}
            />
          </div>
          <div className={Style.contentPressure}>
            {t('common:pressure.hot')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.front_pressure}
            />
          </div>
        </div>
        <div className={Style.tire} style={{ textAlign: 'right' }}>
          <b className={Style.tireTitle}>{t('common:pressure.tire_fr')}</b>
          <div style={{ paddingTop: '8px' }}>
            {t('run:vehicle.gum_hardness')} :
          </div>
          <b className={Style.numberGum}>
            {run.front_right_rally_gum_notch
              ? run.front_right_rally_gum_notch.notch
              : '-'}
          </b>
          <b style={{ display: 'block', paddingTop: '10px' }}>
            {t('run:vehicle.pressure_recommendation')} :
          </b>
          <div className={Style.contentPressure} style={{ paddingTop: '10px' }}>
            {t('common:pressure.cold')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.front_min_cold_pressure}
            />
          </div>
          <div className={Style.contentPressure}>
            {t('common:pressure.hot')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.front_pressure}
            />
          </div>
        </div>
        <div className={Style.tire}>
          <b className={Style.tireTitle}>{t('common:pressure.tire_rl')}</b>
          <div style={{ paddingTop: '8px' }}>
            {t('run:vehicle.gum_hardness')} :
          </div>
          <b className={Style.numberGum}>
            {run.rear_left_rally_gum_notch
              ? run.rear_left_rally_gum_notch.notch
              : '-'}
          </b>
          <b style={{ display: 'block', paddingTop: '10px' }}>
            {t('run:vehicle.pressure_recommendation')} :
          </b>
          <div className={Style.contentPressure} style={{ paddingTop: '10px' }}>
            {t('common:pressure.cold')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.rear_min_cold_pressure}
            />
          </div>
          <div className={Style.contentPressure}>
            {t('common:pressure.hot')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.rear_pressure}
            />
          </div>
        </div>
        <div className={Style.tire} style={{ textAlign: 'right' }}>
          <b className={Style.tireTitle}>{t('common:pressure.tire_rr')}</b>
          <div style={{ paddingTop: '8px' }}>
            {t('run:vehicle.gum_hardness')} :
          </div>
          <b className={Style.numberGum}>
            {run.rear_right_rally_gum_notch
              ? run.rear_right_rally_gum_notch.notch
              : '-'}
          </b>
          <b style={{ display: 'block', paddingTop: '10px' }}>
            {t('run:vehicle.pressure_recommendation')} :
          </b>
          <div className={Style.contentPressure} style={{ paddingTop: '10px' }}>
            {t('common:pressure.cold')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.rear_min_cold_pressure}
            />
          </div>
          <div className={Style.contentPressure}>
            {t('common:pressure.hot')} :{' '}
            <PressureDisplay
              pressureValue={run.rally_dimension.rear_pressure}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RunsRallyMyVehicle
