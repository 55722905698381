import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import data from 'data/retailers.json'
import Style from './Retailers.module.scss'
import RetailersMap from './RetailersMap/RetailersMap'
import RetailersAutocomplete from './RetailersAutocomplete/RetailersAutocomplete'

const Retailers = () => {
  const { t } = useTranslation('homepage')
  const [coordinates, setCoordinates] = useState([])

  return (
    <div className={Style.card}>
      <h2>{t('homepage:retailers:title')}</h2>
      <RetailersAutocomplete
        handleClickRetailer={(value) => setCoordinates(value)}
      />
      <RetailersMap
        data={data}
        retailerCoordinates={coordinates}
        resetCoordinates={() => setCoordinates([])}
      />
    </div>
  )
}

export default Retailers
