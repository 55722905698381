import React from 'react'
import Style from './Gauge.module.scss'

const Gauge = ({ value }) => {
  const valueInDeg = value * 1.8
  return (
    <span className={Style.gauge}>
      <span className={Style.outer}>
        <span className={Style.inner} />
        <span
          className={Style.cover}
          style={{ transform: `rotate(${valueInDeg}deg)` }}
        />
        <span className={Style.value}>{value}%</span>
      </span>
    </span>
  )
}

export default Gauge
