import React from 'react'
import 'rc-slider/assets/index.css'
import {
  format,
  fromUnixTime,
  differenceInDays,
  differenceInSeconds,
} from 'date-fns'
import Flex from 'components/Flex'
import { ReactComponent as IconHumid } from 'img/picto_humide.svg'
import { ReactComponent as IconHumidMore } from 'img/picto_mouille.svg'
import { ReactComponent as IconDry } from 'img/picto_sec.svg'
import { ReactComponent as IconHumidLess } from 'img/picto_humid_less_15_celsius.svg'
import formatMinSec from 'utils/formatMinSec'
import DistanceDisplay from 'components/DistanceDisplay'
import { useTranslation } from 'react-i18next'
import Style from './RunsRallyInfos.module.scss'

import RunsRallyInfosSlider from './RunsRallyInfosSlider'
import RunsRallyInfosMap from './RunsRallyInfosMap'
import RunsRallyInfosMedia from './RunsRallyInfosMedia'
import RunsRallyInfosTitle from './RunsRallyInfosTitle'

const weather = (type, t) => {
  switch (type) {
    case 0:
      return ''
    case 1:
      return (
        <>
          <IconDry /> {t('run:weather.type_sec')}
        </>
      )
    case 2:
      return (
        <>
          <IconHumidLess />
          {t('run:weather.type_humid_less')}
        </>
      )
    case 3:
      return (
        <>
          <IconHumid />
          {t('run:weather.type_wet')}
        </>
      )
    case 4:
      return (
        <>
          <IconHumidMore />
          {t('run:weather.type_humid_more')}
        </>
      )
    default:
      return ''
  }
}

const RunsRallyInfo = ({ run, name, kilometers, sessionId }) => {
  const startDateFormated = format(fromUnixTime(run.start_date), 'H:mm')
  const endDateFormated = format(fromUnixTime(run.end_date), 'H:mm')
  const { t } = useTranslation('run')

  const diffInS = differenceInSeconds(
    fromUnixTime(run.end_date),
    fromUnixTime(run.start_date)
  )

  return (
    <div className={Style.runInfos}>
      <div className={Style.title}>
        <RunsRallyInfosTitle
          name={name}
          startDate={run.start_date}
          sessionId={sessionId}
        />
      </div>
      <Flex container justifyContent="space-between" margin="32px 0 24px 0">
        <div>
          {t('run:info.days_ago', {
            days: differenceInDays(new Date(), fromUnixTime(run.start_date)),
          })}{' '}
          <b>{format(fromUnixTime(run.start_date), 'dd.MM.yyyy')}</b>
        </div>
      </Flex>
      <Flex
        container
        justifyContent="space-between"
        margin="0 0 18px 0"
        className={Style.contentInfos}
      >
        <div className={Style.contentInfosLeft}>
          <div>
            {t('run:info.departure_time')} :{' '}
            <b>{startDateFormated.replace(/:/, 'h')}</b>
          </div>
          <div>
            {t('run:info.arrival_time')} :{' '}
            <span>{endDateFormated.replace(/:/, 'h')}</span>
          </div>
          <div>
            {t('run:info.duration')} : <span>{formatMinSec(diffInS)}</span>
          </div>
        </div>
        <div className={Style.contentInfosRight}>
          <div>
            {t('run:info.recorded')} :{' '}
            <span>
              <DistanceDisplay distanceValue={kilometers} />
            </span>
          </div>
          <Flex container alignItems="center" flex="none">
            {t('run:info.driving_conditions')} :
            <Flex container alignItems="center" flex="none">
              {weather(run.weather_type, t)}
            </Flex>
          </Flex>
        </div>
      </Flex>
      <RunsRallyInfosMap
        rallyCarLocations={run.rally_car_locations}
        tirePressures={run.rally_tire_pressures}
        tireBoundaries={run.rally_dimension.rally_boundaries.filter(
          (boundarie) => boundarie.weather_type === run.weather_type
        )}
      />
      <RunsRallyInfosSlider
        startDate={run.start_date}
        endDate={run.end_date}
        isVisible={run.rally_car_locations.length !== 0}
      />
      <RunsRallyInfosMedia isVisible={run.rally_car_locations.length !== 0} />
    </div>
  )
}

export default RunsRallyInfo
