import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import { ReactComponent as IconEdit } from 'img/icon_edit.svg'
import { ReactComponent as IconNextArrow } from 'img/icon_arrow_next.svg'
import Select from 'components/Select'
import Style from './VehicleInfosDimensionsOfTyre.module.scss'

const VehicleInfosDimensionsOfTyre = ({
  dimensions,
  handleChangeDimensionId,
  dimension,
}) => {
  const [showEditDimenions, setShowEditDimensions] = useState(false)
  const { t } = useTranslation(['common', 'vehicle'])

  const options = dimensions.map((d) => ({
    id: d.id,
    value: `${d.front_dimension} / ${d.rear_dimension}`,
  }))

  return (
    <Flex
      container
      alignItems="center"
      flex="none"
      className={Style.dimensionsOfTyre}
      height="30px"
    >
      <b>{t('vehicle:dimensions_of_tyre_label')} :</b>{' '}
      <span>
        {showEditDimenions ? (
          <span className={Style.edit}>
            <Select
              handleChange={handleChangeDimensionId}
              value={dimension.id}
              options={options}
            />
            <IconNextArrow onClick={() => setShowEditDimensions(false)} />
          </span>
        ) : (
          <>
            <b>{t('common:tire.av')}</b>:{dimension.front_dimension} <br />
            <b>{t('common:tire.ar')}</b>:{dimension.rear_dimension}
          </>
        )}
      </span>
      {!showEditDimenions && (
        <IconEdit onClick={() => setShowEditDimensions(true)} />
      )}
    </Flex>
  )
}

export default VehicleInfosDimensionsOfTyre
