import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { PreferencesContext } from '../contexts/PreferencesContext'
import { PREFERENCE_TEMPERATURE_DEFAULT } from '../constants/preferences'
import { convertCelciusToF } from '../utils/convertTemperature'

const TemperatureDisplay = ({ temperatureValue }) => {
  const {
    preferences: { temperature },
  } = useContext(PreferencesContext)

  return (
    <>
      {temperature === PREFERENCE_TEMPERATURE_DEFAULT
        ? temperatureValue.toFixed(2)
        : convertCelciusToF(temperatureValue)}
    </>
  )
}

TemperatureDisplay.defaultProps = {
  temperatureValue: '',
}

TemperatureDisplay.propTypes = {
  temperatureValue: PropTypes.string,
}

export default TemperatureDisplay
