import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { getStageRunsForSession } from 'services/rallySessionService'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { format, fromUnixTime, differenceInSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ReactComponent as IconCalendar } from 'img/icon_calendar.svg'
import { ReactComponent as IconLocation } from 'img/icon_location.svg'
import { ReactComponent as IconGarage } from 'img/icon_garage.svg'
import { ReactComponent as IconTire } from 'img/icon_tire.svg'
import { ReactComponent as IconChrono } from 'img/icon_chrono.svg'

import Flex from 'components/Flex'
import NextArrow from 'components/NextArrow'
import PrevArrow from 'components/PrevArrow'
import formatMinSec from 'utils/formatMinSec'
import Style from './Session.module.scss'

const Session = ({ item }) => {
  const history = useHistory()
  const { t } = useTranslation(['homepage', 'common'])

  const goToRun = (idSession, idRun) => {
    history.push(`session/${idSession}/run/${idRun}`)
  }

  const rallyRuns = getStageRunsForSession(item)

  return (
    <div className={Style.session}>
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className={Style.sessionRally}>
          <div className={Style.sessionTitle}>{item.name}</div>
          <div className={Style.sessionDate}>
            <IconCalendar />
            {format(fromUnixTime(item.date), 'dd.MM.yyyy')}
          </div>
          <div className={Style.sessionLocation}>
            <IconLocation />
            {item.location} - {item.country}
          </div>
        </div>
        <div className={Style.sessionVehicle}>
          <div className={Style.sessionVehicleTitle}>
            <IconGarage />
            <b>
              {
                rallyRuns[0]?.rally_dimension.rally_group.rally_model
                  .rally_brand.name
              }
            </b>
            - {rallyRuns[0]?.rally_dimension.rally_group.rally_model.name}
          </div>
          <div className={Style.sessionVehicleTire}>
            <IconTire />
            <b>{t('common:tire.av')}</b>:{' '}
            {rallyRuns[0]?.rally_dimension.front_dimension}
          </div>
          <div className={Style.sessionVehicleTire}>
            <IconTire />
            <b>{t('common:tire.ar')}</b>:{' '}
            {rallyRuns[0]?.rally_dimension.rear_dimension}
          </div>
        </div>
        <Slider
          dots={false}
          slidesToShow={4}
          infinite={false}
          focusOnSelect={false}
          slidesToScroll={4}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {rallyRuns
            .sort((a, b) => a.start_date - b.start_date)
            .map((stage, index) => {
              const diffInS = differenceInSeconds(
                fromUnixTime(stage.end_date),
                fromUnixTime(stage.start_date)
              )

              const dateFormated = format(
                fromUnixTime(stage.start_date),
                'H:mm'
              )
              return (
                <div
                  role="button"
                  className={Style.stages}
                  key={stage.id}
                  onClick={() => goToRun(item.id, stage.id)}
                  onKeyPress={() => goToRun(item.id, stage.id)}
                >
                  <div className={Style.slide}>
                    <div>
                      {t('common:stage.title')} {index + 1}
                    </div>
                    <div>{t('homepage:last_session.stage_departure')}: </div>
                    <div>{dateFormated.replace(/:/, 'h')}</div>
                    <div>
                      <IconChrono />
                      {formatMinSec(diffInS)}
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </Flex>
    </div>
  )
}

Session.defaultProps = {
  item: {},
}

Session.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.string,
    country: PropTypes.string,
    rally_runs: PropTypes.arrayOf(
      shape({
        id: PropTypes.number,
        date: PropTypes.number,
        start_date: PropTypes.number,
        recorded_time: PropTypes.number,
      })
    ),
  }),
}

export default Session
