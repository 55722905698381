import React, { useContext, useEffect, useState } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useHistory, useParams } from 'react-router-dom'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import TYPE from 'constants/catalogs'
import { getCatalogsItems, getCatalogsTags } from 'services/catalogService'
import Filters from 'components/Filters/Filters'
import Pagination from 'components/Pagination/Pagination'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import paginate from 'utils/paginate'
import { NotificationContext } from 'contexts/NotificationContext'
import Style from './Catalog.module.scss'
import CatalogList from './CatalogList/CatalogList'

Modal.setAppElement('#modal')

const limit = 8

const Catalog = () => {
  const [tagSelected, setTagSelected] = useStateIfMounted(null)
  const [catalogs, setCatalogs] = useStateIfMounted([])
  const [tags, setTags] = useStateIfMounted([])
  const [catalogSelected, setCatalogSelected] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  // state for pagination
  const [pageIndex, setPageIndex] = useStateIfMounted(0)
  const [pagesToPaginate, setPagesToPaginate] = useStateIfMounted([])
  const [totalPagination, setTotalPagination] = useStateIfMounted(null)

  const { openSnackBar } = useContext(NotificationContext)

  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslation('catalog')

  const fetchCatalogsAndTags = async () => {
    const token = localStorage.getItem('access_token')

    try {
      const {
        data: { catalog_items, catalog_item_count },
      } = await getCatalogsItems(token, TYPE.PDF, limit, pageIndex)
      const responseTags = await getCatalogsTags(token)

      const listCatalogsTagsWithoutDuplicate = [
        ...new Set(catalog_items.flatMap((item) => item.catalog_tags)),
      ]
      const tagsPDF = responseTags.data.filter((tag) =>
        listCatalogsTagsWithoutDuplicate.includes(tag.id)
      )

      if (id) {
        const catalog = catalog_items.find((c) => c.id === Number(id))
        setTagSelected(
          tagsPDF.find((tag) => tag.id === catalog.catalog_tags[0])
        )
      } else {
        setTagSelected(tagsPDF[0])
      }
      setCatalogs(catalog_items)
      setTags(tagsPDF)

      const { pages, totalPages } = paginate(catalog_item_count, pageIndex)
      setTotalPagination(totalPages)
      setPagesToPaginate(pages)
    } catch (error) {
      openSnackBar(
        `${t('catalog:notification.get_data.error')}: ${error.message}`,
        'error'
      )
    }
  }

  useEffect(() => {
    fetchCatalogsAndTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    if (id && catalogs.length !== 0) {
      const catalog = catalogs.find((c) => c.id === Number(id))
      setCatalogSelected(catalog)
      setModalIsOpen(true)
    }
  }, [id, catalogs])

  const closeModal = () => {
    setModalIsOpen(false)
    setCatalogSelected(null)
  }

  const openModal = (catalog) => {
    setCatalogSelected(catalog)
    setModalIsOpen(true)
  }

  const listEmpty = tags.length === 0 && catalogs.length === 0

  if (!tagSelected) {
    return null
  }

  return (
    <>
      <Filters
        goBack={history.goBack}
        noCalendar
        items={tags}
        context="catalog"
        idCurrent={tagSelected.id}
        onChange={setTagSelected}
      />
      <div className="content">
        <h1>
          {t('catalog:title')} {tagSelected.name}
        </h1>
        {!listEmpty && (
          <CatalogList
            catalogs={catalogs.filter((catalog) =>
              catalog.catalog_tags.includes(tagSelected.id)
            )}
            tagSelectedName={tagSelected.name}
            openModal={openModal}
          />
        )}
      </div>
      {pagesToPaginate.length !== 0 && (
        <Pagination
          gotoPage={(index) => setPageIndex(index)}
          canPreviousPage={pageIndex >= 1}
          canNextPage={pageIndex + 1 !== totalPagination}
          previousPage={() => setPageIndex(pageIndex - 1)}
          nextPage={() => setPageIndex(pageIndex + 1)}
          pageIndex={pageIndex}
          pageCount={totalPagination}
          pagesToPaginate={pagesToPaginate}
        />
      )}
      {catalogSelected && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={Style.modal}
          overlayClassName="Overlay"
        >
          <PDFViewer pdf={catalogSelected.link} />
        </Modal>
      )}
    </>
  )
}

export default Catalog
