import React from 'react'
import Rating from 'react-rating'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Flex from 'components/Flex'
import Vehicle from 'img/vehicle.svg'
import { ReactComponent as IconStarEmpty } from 'img/icon_star_empty.svg'
import { ReactComponent as IconStarFilled } from 'img/icon_star_filled.svg'
import Gauge from 'components/Gauge/Gauge'

import Style from './OptimalRangeOperation.module.scss'

const OptimalRangeOperation = ({
  front_optimal_range,
  rear_optimal_range,
  showImg,
  className,
}) => {
  const front = Math.ceil(Math.round(front_optimal_range) / 20)
  const rear = Math.ceil(Math.round(rear_optimal_range) / 20)
  const { t } = useTranslation('common')

  return (
    <div className={className}>
      <h2>{t('common:optimal_range_operation.title')}</h2>
      {front_optimal_range == null ||
      front_optimal_range < 0 ||
      front_optimal_range > 100 ? (
        <Flex container justifyContent="center">
          <span className={Style.noData}>{t('common:no_data')}</span>
        </Flex>
      ) : (
        <Flex container flexDirection="row" justifyContent="space-around">
          <div style={{ padding: '0 20px' }}>
            <div className={Style.title}>{t('common:tire.front')}</div>
            <Rating
              total={5}
              initialRating={front}
              readonly
              emptySymbol={<IconStarEmpty />}
              fullSymbol={<IconStarFilled />}
            />
            <Gauge value={front_optimal_range.toFixed()} />
          </div>
          {showImg && <img src={Vehicle} alt="car" />}
          <div style={{ padding: '0 20px' }}>
            <div className={Style.title}>{t('common:tire.rear')}</div>
            <Rating
              total={5}
              initialRating={rear}
              readonly
              emptySymbol={<IconStarEmpty />}
              fullSymbol={<IconStarFilled />}
            />
            <Gauge value={rear_optimal_range.toFixed()} />
          </div>
        </Flex>
      )}
    </div>
  )
}

OptimalRangeOperation.defaultProps = {
  front_optimal_range: undefined,
  rear_optimal_range: undefined,
  showImg: true,
  className: undefined,
}

OptimalRangeOperation.propTypes = {
  front_optimal_range: PropTypes.number,
  rear_optimal_range: PropTypes.number,
  showImg: PropTypes.bool,
  className: PropTypes.string,
}

export default OptimalRangeOperation
