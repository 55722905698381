import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import Styles from './Snackbar.module.scss'

const Snackbar = ({ isActive, message, severity }) => {
  return (
    <div
      className={ClassNames(Styles.snackbar, {
        [Styles.fadeIn]: isActive,
        [Styles.fadeOut]: !isActive,
        [Styles.error]: severity === 'error',
        [Styles.success]: severity === 'success',
      })}
    >
      {message}
    </div>
  )
}

Snackbar.defaultProps = {
  isActive: false,
  message: '',
  severity: 'success',
}

Snackbar.propTypes = {
  isActive: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string,
}

export default Snackbar
