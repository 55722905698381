import React from 'react'
import TirePressuresValue from 'components/TirePressureValue/TirePressuresValue'
import { PRESSURE } from 'constants/tire'
import Style from './TirePressures.module.scss'

const TirePressures = ({ tireFL, tireRL, tireFR, tireRR, context }) => {
  const getValueWithColor = (value) => {
    return context === PRESSURE ? value.press : value.temp
  }

  return (
    <>
      <div className={`${Style.pressure} ${Style.pressureL}`}>
        <div className={Style.pressureTitle}>FL</div>
        <TirePressuresValue
          value={getValueWithColor(tireFL)}
          context={context}
        />
      </div>
      <div className={`${Style.pressure} ${Style.pressureR}`}>
        <div className={Style.pressureTitle}>FR</div>
        <TirePressuresValue
          value={getValueWithColor(tireFR)}
          context={context}
        />
      </div>
      <div className={`${Style.pressure} ${Style.pressureL}`}>
        <div className={Style.pressureTitle}>RL</div>
        <TirePressuresValue
          value={getValueWithColor(tireRL)}
          context={context}
        />
      </div>
      <div className={`${Style.pressure} ${Style.pressureR}`}>
        <div className={Style.pressureTitle}>RR</div>
        <TirePressuresValue
          value={getValueWithColor(tireRR)}
          context={context}
        />
      </div>
    </>
  )
}

export default TirePressures
