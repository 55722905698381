import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStateIfMounted } from 'use-state-if-mounted'

import Flex from 'components/Flex'
import Filters from 'components/Filters/Filters'
import rallyCarService from 'services/rallyCarService'
import { NotificationContext } from 'contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import Style from './VehicleDetails.module.scss'
import VehicleInfos from './VehicleInfos'
import CamberAngle from './CamberAngle'
import PressureRecommendation from './PressureRecommendation'

// TODO refacto name/folder Vehicle
const VehicleDetail = () => {
  const history = useHistory()
  const { id } = useParams()
  const [data, setData] = useStateIfMounted(undefined)
  const { openSnackBar } = useContext(NotificationContext)

  const [frontCamber, setFrontCamber] = useStateIfMounted({
    isEditable: false,
    front: undefined,
  })
  const [rearCamber, setRearCamber] = useStateIfMounted({
    isEditable: false,
    rear: undefined,
  })

  const [dimensionId, setDimensionId] = useStateIfMounted(undefined)
  const [dimensions, setDimensions] = useStateIfMounted([])
  const { t } = useTranslation(['run', 'vehicle', 'button'])
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    async function getRallyCars() {
      try {
        const response = await rallyCarService.findAll(token)
        const vehicle = response.data.find((d) => d.id === Number(id))

        setFrontCamber({ isEditable: false, front: vehicle.front_camber })
        setRearCamber({ isEditable: false, rear: vehicle.rear_camber })
        setDimensionId(vehicle.rally_dimension.id)
        setData(vehicle)
      } catch (error) {
        openSnackBar(
          `${t('run:vehicle.notification.get_data.error')}: ${error.message}`,
          'error'
        )
      }
    }
    getRallyCars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const removeVehicle = async () => {
    try {
      await rallyCarService.deleteCar(token, id)
      openSnackBar(t('run:vehicle.notification.delete.success'), 'success')
    } catch (error) {
      openSnackBar(
        `${t('run:vehicle.notification.delete.error')}: ${error.message}`,
        'error'
      )
    }
    history.push('/vehicles')
  }

  const updateVehicle = async () => {
    try {
      const dimension = dimensions.find((d) => d.id === dimensionId)

      const body = {
        front_camber: parseFloat(frontCamber.front),
        rear_camber: parseFloat(rearCamber.rear),
        rally_dimension_id: dimension.id,
        receiver_id: data.receiver.id,
        number: data.number,
      }

      await rallyCarService.updateCar(token, id, body)
      openSnackBar(t('run:vehicle.notification.update.success'), 'success')
    } catch (error) {
      openSnackBar(
        `${t('run:vehicle.notification.update.error')}: ${error.message}`,
        'error'
      )
    }
  }

  return (
    <>
      <Filters goBack={history.goBack} noCalendar />
      <div className="content">
        <Flex
          container
          flexDirection="column"
          className={Style.vehiclesDetails}
        >
          <h1>{t('vehicle:title')}</h1>

          {data && (
            <>
              <Flex container justifyContent="space-between" height="100%">
                <VehicleInfos
                  rally_dimension={data.rally_dimension}
                  receiver={data.receiver}
                  front_optimal_range_operation={
                    data.front_optimal_range_operation
                  }
                  rear_optimal_range_operation={
                    data.rear_optimal_range_operation
                  }
                  dimensions={dimensions}
                  handleChangeDimensions={(values) => setDimensions(values)}
                  dimensionId={dimensionId}
                  handleChangeDimensionId={(event) => {
                    setDimensionId(Number(event.target.value))
                  }}
                />
                <CamberAngle
                  rear_camber={rearCamber}
                  front_camber={frontCamber}
                  handleChangeRear={(editable, value) =>
                    setRearCamber({ isEditable: editable, rear: value })
                  }
                  handleChangeFront={(editable, value) =>
                    setFrontCamber({ isEditable: editable, front: value })
                  }
                />
                <PressureRecommendation
                  pressureRecommendation={data.rally_dimension}
                />
              </Flex>
              <Flex
                container
                justifyContent="space-between"
                flex="initial"
                padding="40px 0"
                className={Style.contentActions}
              >
                <button
                  type="button"
                  className={Style.buttonRemove}
                  onClick={removeVehicle}
                >
                  {t('button:remove')}
                </button>
                <div>
                  <button
                    type="button"
                    className={Style.buttonCancel}
                    onClick={() => history.push('/vehicles')}
                  >
                    {t('button:cancel')}
                  </button>
                  <button
                    type="button"
                    className={Style.buttonValide}
                    onClick={updateVehicle}
                  >
                    {t('button:validate')}
                  </button>
                </div>
              </Flex>
            </>
          )}
        </Flex>
      </div>
    </>
  )
}

export default VehicleDetail
