import React from 'react'
import { ReactComponent as IconTire } from 'img/icon_tire.svg'
import { ReactComponent as IconNextArrow } from 'img/icon_arrow_next.svg'
import Style from './CatalogListItem.module.scss'

const CatalogListItem = ({ catalog, tagSelectedName, openModal }) => {
  return (
    <div
      className={Style.catalogListItem}
      onClick={() => openModal(catalog)}
      role="button"
      onKeyPress={() => openModal(true)}
    >
      <IconTire className={Style.iconTire} />
      <div className={Style.detail}>
        <span>{tagSelectedName}</span> <br />
        <b>{catalog.name}</b>
      </div>
      <IconNextArrow className={Style.iconArrow} />
    </div>
  )
}

export default CatalogListItem
