import React, { useEffect, useRef, useContext } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useStateIfMounted } from 'use-state-if-mounted'
import { TimeContext } from 'contexts/TimeContext'
import { PRIMARY_COLOR, GREY_DARK_COLOR } from 'constants/colors'
import Style from './Map.module.scss'
import getPositionLabel from '../../utils/getPositionLabel'
import getColorPressure from '../../utils/getColorPressure'

const getLinearGradient = (tirePressures, rallyCarLocation, tireBoundaries) => {
  const arr = []
  // let previousColor = ''

  tirePressures.forEach((element) => {
    const colorPressure = getColorPressure(
      true,
      element.pressure,
      tireBoundaries
    )
    // MTF-137 Tracé carte // Incohérence couleurs
    // if (previousColor !== colorPressure) {
    const index = rallyCarLocation.findIndex(
      (rally) =>
        rally[getPositionLabel(element.position)] === element.trame_date
    )
    if (index !== -1) {
      // donne une valeur entre 0 et 1
      const positionOnCircuit = Number(
        (index / rallyCarLocation.length).toFixed(2)
      )
      const lastPosition = arr[arr.length - 2]
      if (lastPosition !== positionOnCircuit) {
        arr.push(positionOnCircuit)
        arr.push(colorPressure)
        // previousColor = colorPressure
      }
    }
    // }
  })
  return [...['interpolate', ['linear'], ['line-progress']], ...arr]
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX

const Map = ({ data, tirePressures, tireBoundaries }) => {
  const { timeSlider, isReset } = useContext(TimeContext)
  const mapContainerRef = useRef(null)
  const [displayedMap, setDisplayedMap] = useStateIfMounted(null)

  // Initialize map when component mounts
  useEffect(() => {
    const initializeMap = ({ setMap, ref }) => {
      if (!ref.current || data.length === 0) {
        return
      }

      const map = new mapboxgl.Map({
        container: ref.current || '',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [data[0].longitude, data[0].latitude],
        zoom: 12,
      })

      map.on('load', function loadMap() {
        const coordinates = data.map((d) => [d.longitude, d.latitude])

        map.addSource('lines', {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  color: PRIMARY_COLOR,
                },
                geometry: {
                  type: 'LineString',
                  coordinates,
                },
              },
            ],
          },
        })
        map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'Point',
            coordinates: coordinates[0],
          },
        })
        map.addLayer({
          id: 'lines',
          type: 'line',
          source: 'lines',
          paint: {
            'line-width': 3,
            // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
            // to set the line-color to a feature property value.
            'line-color': 'red',
            'line-gradient': getLinearGradient(
              tirePressures,
              data,
              tireBoundaries
            ),
          },
        })
        map.addLayer({
          id: 'point',
          source: 'point',
          type: 'circle',
          paint: {
            'circle-radius': 8,
            'circle-color': GREY_DARK_COLOR,
          },
        })

        setMap(map)
      })

      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        })
      )
    }

    if (!displayedMap || isReset) {
      initializeMap({
        setMap: setDisplayedMap,
        ref: mapContainerRef,
      })
    }
    // Clean up on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedMap, data])

  useEffect(() => {
    const coordinates = data
      .sort((a, b) => a.chrono - b.chrono)
      .map((d) => [d.longitude, d.latitude])

    if (displayedMap) {
      const obj = {
        type: 'Point',
        // fix error 0 undefined. Because the start at 0s. So there is one step too far from the table rally_car_locations
        coordinates:
          timeSlider === 0 || !coordinates[timeSlider]
            ? coordinates[0]
            : coordinates[timeSlider - 1],
      }
      displayedMap.getSource('point').setData(obj)

      setDisplayedMap(displayedMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlider, displayedMap, data])

  return <div ref={mapContainerRef} className={Style.mapContainer} />
}

export default Map
