import React from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from 'components/Avatar/Avatar'
import Flex from 'components/Flex'
import Style from './Account.module.scss'
import AccountInformation from './Information/AccountInformation'

const Account = () => {
  const { t } = useTranslation('account')
  return (
    <div className={Style.content}>
      <h1>{t('account:title')}</h1>
      <Flex
        container
        flexDirection="row"
        margin="40px 0 0 0"
        alignItems="center"
      >
        <AccountInformation />
        <Avatar size={251} url={localStorage.getItem('picture')} />
      </Flex>
    </div>
  )
}

export default Account
