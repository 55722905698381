import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { convertKmToMiles } from '../utils/convertDistance'
import { PreferencesContext } from '../contexts/PreferencesContext'
import { PREFERENCE_DISTANCE_DEFAULT } from '../constants/preferences'

const DistanceDisplay = ({ distanceValue, noUnit }) => {
  const {
    preferences: { distance },
  } = useContext(PreferencesContext)

  return (
    <>
      {distance === PREFERENCE_DISTANCE_DEFAULT
        ? distanceValue.toFixed(2)
        : convertKmToMiles(distanceValue)}
      {!noUnit && (distance === PREFERENCE_DISTANCE_DEFAULT ? ' km' : ' miles')}
    </>
  )
}

DistanceDisplay.defaultProps = {
  distanceValue: 0,
  noUnit: false,
}

DistanceDisplay.propTypes = {
  distanceValue: PropTypes.number,
  noUnit: PropTypes.bool,
}

export default DistanceDisplay
