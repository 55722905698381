import axios from 'axios'
import { getI18n } from 'react-i18next'

const apiUrl = process.env.REACT_APP_API_URL
const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

const findAll = (token) =>
  axios.get(`${apiPortalUrl}/rally-cars`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

const deleteCar = (token, id) =>
  axios.delete(`${apiUrl}/rally-cars/${id}?state=3`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

const updateCar = (token, id, body) =>
  axios.put(`${apiUrl}/rally-cars/${id}`, body, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': getI18n().language.slice(0, 2),
    },
  })

export default { findAll, deleteCar, updateCar }
