import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PreferencesContext } from 'contexts/PreferencesContext'
import PREFERENCES from 'constants/preferences'
import PreferencesPanelListItemWithSwitch from './PreferencesPanelListItemWithSwitch/PreferencesPanelListItemWithSwitch'
import PreferencesPanelListItemWithSelect from './PreferencesPanelListItemWithSelect/PreferencesPanelListItemWithSelect'

import Style from './PreferencesPanelList.module.scss'

const PreferencesPanelList = () => {
  const { preferences, setPreferences } = useContext(PreferencesContext)
  const { t, i18n } = useTranslation('preferences')
  const languages = [
    {
      id: 'fr-FR',
      value: 'FR',
    },
    {
      id: 'en-GB',
      value: 'EN-GB',
    },
    {
      id: 'de-DE',
      value: 'DE',
    },
    {
      id: 'en-US',
      value: 'EN-US',
    },
    {
      id: 'zh-CN',
      value: 'ZH',
    },
    {
      id: 'nl-NL',
      value: 'NL',
    },
    {
      id: 'de-AT',
      value: 'DE-AT',
    },
    {
      id: 'it-IT',
      value: 'IT',
    },
    {
      id: 'es-ES',
      value: 'ES',
    },
  ]

  return (
    <>
      <div className={Style.title}>{t('preferences:title')}</div>
      <PreferencesPanelListItemWithSelect
        label={t('preferences:language')}
        handleChange={(event) => i18n.changeLanguage(event.target.value)}
        value={i18n.language}
        options={languages}
      />
      <PreferencesPanelListItemWithSwitch
        checkedLabel="BAR"
        uncheckedLabel="PSI"
        checked={preferences.pressure !== PREFERENCES.PRESSURE.BAR}
        label={t('preferences:pressure')}
        handleChecked={(checked) =>
          setPreferences((pref) => {
            return {
              ...pref,
              pressure: checked
                ? PREFERENCES.PRESSURE.PSI
                : PREFERENCES.PRESSURE.BAR,
            }
          })
        }
      />
      <PreferencesPanelListItemWithSwitch
        checkedLabel="°C"
        uncheckedLabel="°F"
        checked={preferences.temperature !== PREFERENCES.TEMPERATURE.CELSIUS}
        label={t('preferences:temperature')}
        handleChecked={(checked) =>
          setPreferences((pref) => {
            return {
              ...pref,
              temperature: checked
                ? PREFERENCES.TEMPERATURE.FAHRENHEIT
                : PREFERENCES.TEMPERATURE.CELSIUS,
            }
          })
        }
      />
      <PreferencesPanelListItemWithSwitch
        checkedLabel="KM"
        uncheckedLabel="MI"
        checked={preferences.distance !== PREFERENCES.DISTANCE.KM}
        label={t('preferences:distance')}
        handleChecked={(checked) =>
          setPreferences((pref) => {
            return {
              ...pref,
              distance: checked
                ? PREFERENCES.DISTANCE.MI
                : PREFERENCES.DISTANCE.KM,
            }
          })
        }
      />
    </>
  )
}

export default PreferencesPanelList
