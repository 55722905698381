import React from 'react'
import Flex from 'components/Flex'
import Select from 'components/Select'
import Style from './PreferencesPanelListItemWithSelect.module.scss'

const PreferencesPanelListItemWithSelect = ({
  label,
  handleChange,
  value,
  options,
}) => {
  return (
    <Flex
      container
      flexDirection="row"
      justifyContent="space-between"
      padding="8px 0"
    >
      <span className={Style.label}>{label}</span>
      <Select
        handleChange={handleChange}
        value={value}
        options={options}
        className={Style.select}
      />
    </Flex>
  )
}

export default PreferencesPanelListItemWithSelect
