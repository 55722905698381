import React from 'react'
import Style from './VignetteTireEssential.module.scss'

const VignetteTireEssential = ({
  tireEssential,
  tagsName,
  width,
  height,
  handleClick,
}) => {
  return (
    <div
      className={Style.tireEssentialListItem}
      style={{ width, height }}
      onClick={(e) => handleClick(tireEssential.id, e)}
      onKeyPress={(e) => handleClick(tireEssential.id, e)}
      role="button"
    >
      {tireEssential.thumbnail !== null ? (
        <img
          src={tireEssential.thumbnail}
          alt="tire essential"
          width="100%"
          height="100%"
        />
      ) : (
        <div className={Style.noImg} />
      )}

      <div className={Style.informations}>
        <b>{tireEssential.name}</b> <br />
        {tagsName.map((tag) => {
          return <span key={tag}>#{tag} </span>
        })}
      </div>
    </div>
  )
}

export default VignetteTireEssential
