import React from 'react'
import Flex from 'components/Flex'
import CatalogListItem from './CatalogListItem/CatalogListItem'

const CatalogList = ({ catalogs, tagSelectedName, openModal }) => {
  if (catalogs.length === 0) {
    return null
  }
  return (
    <Flex container flexDirection="row" flexWrap="wrap">
      {catalogs.map((catalog) => {
        return (
          <CatalogListItem
            catalog={catalog}
            key={catalog.id}
            tagSelectedName={tagSelectedName}
            openModal={openModal}
          />
        )
      })}
    </Flex>
  )
}

export default CatalogList
