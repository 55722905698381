import React from 'react'
import Style from './PreferencesPanel.module.scss'
import { ReactComponent as IconSettings } from '../../../img/icon_settings.svg'
import PreferencesPanelList from './PreferencesPanelList/PreferencesPanelList'

const PreferencesPanel = () => {
  return (
    <div className={Style.preferencesPanel}>
      <IconSettings width={30} height={30} />
      <div className={Style.preferencesPanelList}>
        <PreferencesPanelList />
      </div>
    </div>
  )
}

export default PreferencesPanel
