import React, { useContext } from 'react'
import Flex from 'components/Flex'
import { ReactComponent as IconMediaPrev } from 'img/icon_media_prev.svg'
import { ReactComponent as IconMediaPlay } from 'img/icon_media_play.svg'
import { ReactComponent as IconMediaNext } from 'img/icon_media_next.svg'
import { TimeContext } from 'contexts/TimeContext'
import Style from './RunsRallyInfosMedia.module.scss'

const RunsRallyInfosMedia = ({ isVisible }) => {
  const { play, setPlay, setPrevious, setNext } = useContext(TimeContext)

  if (!isVisible) {
    return null
  }

  return (
    <Flex
      container
      alignItems="center"
      justifyContent="center"
      margin="26px 0"
      className={Style.contentIconMedia}
    >
      <IconMediaPrev onClick={setPrevious} />
      {play ? (
        <div
          aria-label="button"
          role="button"
          className={Style.pause}
          onClick={() => setPlay((p) => !p)}
          onKeyPress={() => setPlay((p) => !p)}
        />
      ) : (
        <IconMediaPlay onClick={() => setPlay((p) => !p)} />
      )}
      <IconMediaNext onClick={setNext} />
    </Flex>
  )
}

export default RunsRallyInfosMedia
