import { getSeconds, getMinutes, getHours, fromUnixTime } from 'date-fns'

function getSecondes(date) {
  const s = getSeconds(fromUnixTime(date))
  const m = getMinutes(fromUnixTime(date))
  const h = getHours(fromUnixTime(date))

  const minutes = h * 60 + m
  return s + minutes * 60
}

export default getSecondes
