import React from 'react'
import { PropTypes } from 'prop-types'
import { ReactComponent as IconTire } from 'img/icon_tire.svg'
import { ReactComponent as IconCatalog } from 'img/icon_catalog.svg'
import NEWS from 'constants/news'
import { Link } from 'react-router-dom'
import Style from './NewsListItem.module.scss'

const NewsListItem = ({ item }) => {
  return (
    <Link
      className={Style.item}
      to={
        item.type === NEWS.YOUTUBE
          ? `/tire-essentials/${item.id}`
          : `/catalogs/${item.id}`
      }
    >
      {item.type === NEWS.YOUTUBE ? (
        <IconTire width={23} height={23} />
      ) : (
        <IconCatalog width={23} height={23} />
      )}
      <span className={Style.content}>
        <div className={Style.description}>{item.description}</div>
        <div className={Style.title}>{item.name}</div>
      </span>
    </Link>
  )
}

NewsListItem.defaultProps = {
  item: {
    type: undefined,
    description: '',
    name: '',
  },
}

NewsListItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default NewsListItem
