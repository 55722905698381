import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import ImgMichelin from 'img/img_michelin.svg'
import Flex from 'components/Flex'
import { useTranslation } from 'react-i18next'
import Style from './RunsRallyTips.module.scss'
import RunsRallyTipsModal from './RunsRallyTipsModal/RunsRallyTipsModal'

Modal.setAppElement('#modal')

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this[this.length - 1]
  }
}

const RunsRallyTips = ({ tirePressures, isVisible, sessionId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation(['run', 'button', 'extra'])
  const [visible, setVisible] = useState(isVisible)
  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const sortedTirePressures = tirePressures.sort(
    (a, b) => a.trame_date - b.trame_date
  )

  // Front right
  const allFR = sortedTirePressures.filter((elt) => {
    return elt.position === 0
  })
  let FR = 0
  if (allFR.length > 0) {
    FR = allFR.last().pressure
  } else {
    setVisible(false)
  }

  // Front left
  const allFL = sortedTirePressures.filter((elt) => {
    return elt.position === 1
  })
  let FL = 0
  if (allFL.length > 0) {
    FL = allFL.last().pressure
  } else {
    setVisible(false)
  }

  // Rear right
  const allRR = sortedTirePressures.filter((elt) => {
    return elt.position === 2
  })
  let RR = 0
  if (allRR.length > 0) {
    RR = allRR.last().pressure
  } else {
    setVisible(false)
  }

  // Rear left
  const allRL = sortedTirePressures.filter((elt) => {
    return elt.position === 3
  })
  let RL = 0
  if (allRL.length > 0) {
    RL = allRL.last().pressure
  } else {
    setVisible(false)
  }

  const messages = []

  // Front Axle
  const frontAxlePressure = (FL + FR) / 2

  if (frontAxlePressure > 2.3) {
    messages.push(t('extra:simple_advice_1', { axle: t('common:tire.av') }))
  } else if (frontAxlePressure > 2.0) {
    messages.push(t('extra:simple_advice_2', { axle: t('common:tire.av') }))
  } else {
    messages.push(t('extra:simple_advice_3', { axle: t('common:tire.av') }))
  }

  // Rear Axle
  const rearAxlePressure = (RL + RR) / 2

  if (rearAxlePressure > 2.3) {
    messages.push(t('extra:simple_advice_1', { axle: t('common:tire.ar') }))
  } else if (rearAxlePressure > 2.0) {
    messages.push(t('extra:simple_advice_2', { axle: t('common:tire.ar') }))
  } else {
    messages.push(t('extra:simple_advice_3', { axle: t('common:tire.ar') }))
  }

  return (
    <div className={Style.tips}>
      <div className={Style.card}>
        <div>
          <h3>{t('run:tips.title')}</h3>

          {!visible ? (
            <div className={Style.noData}>{t('common:no_data')}</div>
          ) : (
            messages.map((content) => (
              <p
                key={content.substring(0, 20)}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ))
          )}
        </div>
        <img src={ImgMichelin} alt="michelin-tips" />
      </div>
      <Flex
        container
        className={Style.contentExport}
        alignItems="center"
        justifyContent="space-between"
      >
        <p>{t('run:tips.description_advices')}</p>
        <button
          type="button"
          className={Style.buttonExport}
          onClick={openModal}
        >
          {t('button:send_run')}
        </button>
      </Flex>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={Style.modal}
        overlayClassName="Overlay"
      >
        <RunsRallyTipsModal sessionId={sessionId} />
      </Modal>
    </div>
  )
}

export default RunsRallyTips
