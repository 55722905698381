import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as IconGarage } from 'img/icon_garage.svg'
import { ReactComponent as IconRuns } from 'img/icon_runs.svg'
import { ReactComponent as IconTire } from 'img/icon_tire.svg'
import { ReactComponent as IconCatalog } from 'img/icon_catalog.svg'
import { ReactComponent as IconHelp } from 'img/icon_help.svg'
import iconHome from 'img/icon_homepage.png'
import Flex from 'components/Flex'
import { ROUTE_HOME } from 'constants/routes'
import Style from './Menu.module.scss'

const Menu = () => {
  const { t } = useTranslation('menu')

  return (
    <Flex
      className={Style.menu}
      flexDirection="column"
      container
      alignItems="center"
      padding="10vh 0 4vh 0"
      justifyContent="space-between"
    >
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        height="calc(100vh - 120px)"
        justifyContent="inherit"
        className={Style.menuContent}
      >
        <div>
          <NavLink to={ROUTE_HOME} activeClassName={Style.selected}>
            <img src={iconHome} alt="home" width="68px" height="68px" />
            <br />
            <span>{t('menu:homepage')}</span>
          </NavLink>
          <br />
          <NavLink to="/runs" activeClassName={Style.selected}>
            <IconRuns />
            <br />
            {t('menu:runs')}
          </NavLink>
          <br />
          <NavLink to="/vehicles" activeClassName={Style.selected}>
            <IconGarage />
            <br />
            {t('menu:garage')}
          </NavLink>
          <br />
          <NavLink to="/tire-essentials" activeClassName={Style.selected}>
            <IconTire />
            <br />
            {t('menu:tire')}
          </NavLink>
          <br />
          <NavLink to="/catalogs" activeClassName={Style.selected}>
            <IconCatalog width={42} height={38} />
            <br />
            {t('menu:catalog')} <br />
            {t('menu:catalog_end')}
          </NavLink>
        </div>
        <div>
          <NavLink to="/help" activeClassName={Style.selected}>
            <IconHelp className={Style.iconHelp} />
            <br />
            {t('menu:help')}
          </NavLink>
        </div>
      </Flex>
    </Flex>
  )
}

export default Menu
