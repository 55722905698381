import React from 'react'
import Flex from 'components/Flex'
import VignetteTireEssential from 'components/VignetteTireEssential/VignetteTireEssential'

const TireEssentialList = ({ tireEssentials, handleClick, tags }) => {
  if (tireEssentials.length === 0) {
    return null
  }

  return (
    <Flex
      container
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-start"
    >
      {tireEssentials.map((tireEssential) => {
        return (
          <VignetteTireEssential
            width="31%"
            height="300px"
            tireEssential={tireEssential}
            key={tireEssential.id}
            tagsName={tireEssential.catalog_tags.map(
              (t) => tags.find((tag) => tag.id === t).name
            )}
            handleClick={handleClick}
          />
        )
      })}
    </Flex>
  )
}

export default TireEssentialList
