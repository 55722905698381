const PREFERENCES = {
  PRESSURE: {
    BAR: 'bar',
    PSI: 'psi',
  },
  TEMPERATURE: {
    CELSIUS: 'celsius',
    FAHRENHEIT: 'fahrenheit',
  },
  DISTANCE: {
    KM: 'km',
    MI: 'mi',
  },
}

export const PREFERENCE_PRESSURE_DEFAULT = PREFERENCES.PRESSURE.BAR
export const PREFERENCE_TEMPERATURE_DEFAULT = PREFERENCES.TEMPERATURE.CELSIUS
export const PREFERENCE_DISTANCE_DEFAULT = PREFERENCES.DISTANCE.KM

export default PREFERENCES
