import React from 'react'
import Switch from 'react-switch'
import ClassNames from 'classnames'

import Style from './PreferencesPanelListItemWithSwitch.module.scss'
import Flex from '../../../../Flex'
import {
  YELLOW_COLOR,
  GREY_LIGHT_COLOR,
  PRIMARY_COLOR,
} from '../../../../../constants/colors'

const PreferencesPanelListItemWithSwitch = ({
  label,
  handleChecked,
  checked,
  uncheckedLabel,
  checkedLabel,
}) => {
  return (
    <Flex
      container
      flexDirection="row"
      justifyContent="space-between"
      padding="8px 0"
    >
      <span className={Style.label}>{label}</span>
      <label style={{ margin: '0 8px' }}>
        <Switch
          onChange={handleChecked}
          checked={checked}
          onColor={GREY_LIGHT_COLOR}
          offColor={GREY_LIGHT_COLOR}
          onHandleColor={YELLOW_COLOR}
          offHandleColor={YELLOW_COLOR}
          checkedIcon={<div className={Style.checkedIcon}>{checkedLabel}</div>}
          className={ClassNames('switch-custom-panel', {
            checked,
          })}
          height={24}
          width={64}
          activeBoxShadow={`0 0 1px 1px ${PRIMARY_COLOR}`}
          uncheckedIcon={
            <div className={Style.uncheckedIcon}>{uncheckedLabel}</div>
          }
        />
      </label>
    </Flex>
  )
}

export default PreferencesPanelListItemWithSwitch
